import axios from 'axios';

const API_MUTUA = axios.create({
  baseURL: "https://api.mutua-autos.recomotor.com",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': 'recomotor.JAErGiqD9m4oW8WbFTaYMtcKOHPD6SIOgCnZaPoje4s8Mn4fBOtvmRdIOAtwWQvqEFLYpRa0tft6G3msN9mVxt0fyUc0VzKLkbQIRPFsMUQUyS1trH86sfk3uBoougGCK1Th1tpdHHdq4e0YSQvl9GYG8u9eIXWnBQCu2YZfvptcZqcTmPYJICY4OqWhtJZztYyxHpPcP42CghouJz537sY7pQfU983dKgiCHXiQ3dU0hHTmfRVYRS'
  },
});

export default API_MUTUA;