import API from '../../index';

export const APIDocumentosAprobados = {
    create: async (body) => {
        return await API.post('/documentos-aprobados', body).then(res => res.data);
    },
    sendEmailRecogida : async (body) => {
        return await API.post('/documentos-aprobados/sendEmailRecogida', body).then(res => res.data);
    },
    sendEmailReproRecogida : async (body) => {
        return await API.post('/documentos-aprobados/sendEmailReproRecogida', body).then(res => res.data);
    },
};