import API from '../../index';

export const APIPoblaciones = {
    get: async (searchTerm) => {
      try {
        const response = await API.get(`/poblaciones?searchTerm=${searchTerm}`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    getById: async (poblacionId) => {
      try {
        const response = await API.get(`/poblaciones/${poblacionId}`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };  