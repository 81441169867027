import PropTypes from 'prop-types';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../images/logo399x69.png';
import initImage from '../../images/initImage.jpeg';
import { authenticate } from "../../services/auth";
import './login.scss';

export default function Login({ setToken }) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";
    const email = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        const response = await authenticate({
            email: email.value,
            password: password.value
        }).catch(function (error) {
            setError(error)
            showErrorToast(error.response.data.error);
        });

        if (response && response.success) {
            setToken(response.user);
            navigate(from, { replace: true });
        }
    };

    return (
        <div className="container w-100 h-100 d-flex flex-column align-items-center justify-content-center" style={{backgroundImage: `url(${initImage})`, backgroundRepeat: 'round', maxWidth: '1920px'}}>
            <ToastContainer />
            <div className="col-md-7 col-lg-5 col-xl-4 py-4 px-5">
                <img src={logo} className="logo d-block m-auto mt-3 mb-4" alt="Recomotor" />

                <form className="form d-flex flex-column align-items-stretch pt-3 pb-4" autoComplete="on" onSubmit={handleLogin}>
                    <div className="form-group mb-3">
                        <input type="text" className="form-control" placeholder="Email" {...email} />
                    </div>

                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" {...password} />
                    </div>

                    <button type="submit" className="btn btn-primary mt-3">Acceder</button>
                </form>
            </div>
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}