import { faCircleInfo, faCircleXmark, faClock, faFileLines, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@mui/material/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APICompras } from "../../../../services/api_zeus/compras/ofertas/";
import ViewerDialog from "../HistoricoBajas/Dialogs/ViewerDialog";
import './CompraFIATC.scss';
import InfoVehiculoDialog from "./Dialogs/InfoVehiculoDialog";
import useScreenWidth from '../../../../hooks/useScreenWidth';
import SkeletonEachResult from '../shared/components/CompraVehiculos/SkeletonEachResult';
import TooltipIcon from '../shared/components/CompraVehiculos/TooltipIcon';
import ColumnHeader from '../shared/components/CompraVehiculos/ColumnHeader';

export default function CompraFIATC({ isEdit, onSubmitCompany }) {
    const limit = 15;
    const [ofertas, setOfertas] = useState([]);
    const [ofertasFiltered, setOfertasFiltered] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState(false);
    const currPage = parseInt(1);
    const [currentPage, setPage] = useState(currPage);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [dataUrl, setDataUrl] = useState(null);
    const userData = JSON.parse(localStorage.getItem("user")) || {};
    const [isInfoVehiculoDialog, setIsInfoVehiculoDialog] = useState(false);
    const [selectedOferta, setSelectedOferta] = useState(null);
    const [activeTab, setActiveTab] = useState("pendientes"); // Track the active tab
    const [isLoading, setIsLoading] = useState(false)

    const screenWidth = useScreenWidth()

    const handleCloseViewer = () => {
        setViewerOpen(false);
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'bottom-left',
        });
    };

    const showInfoToast = (message) => {
        toast.info(message, {
            position: 'bottom-left',
        });
    };

    const handleInfoVehiculoClick = (e, oferta) => {
        setIsInfoVehiculoDialog(true)
        setSelectedOferta(oferta);
    };

    const handleOferta = async (e, oferta) => {
        let bidValue = document.getElementById(oferta.id + "#BidValue").value;
        const data = { "id": oferta.id, "BidValue": bidValue };
        try {
            const resultado = await APICompras.resultadoOferta(data);
            showInfoToast(resultado.Message);
            fetchData(activeTab === "pendientes" ? 0 : 1);
        } catch (error) {
            showErrorToast('Error al realizar la oferta' + error);
        }
    };

    const handleRechazarOferta = async (e, oferta) => {
        const data = { "id": oferta.id };
        try {
            const resultado = await APICompras.rechazarOferta(data);
            showInfoToast(resultado.Message);
            fetchData(activeTab === "pendientes" ? 0 : 1);
        } catch (error) {
            showErrorToast('Error al rechazar la oferta' + error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchData(activeTab === "pendientes" ? 0 : 1, newPage);
    }

    const fetchData = async (estado, currentPage) => {
        try {
            setIsLoading(true)
            setOfertas([])
            const ofertasData = await APICompras.obtenerDatosOfertas({
                "estado": estado,
                "pagina": currentPage || 1,
                "resultadosPorPagina": limit
            });
            setOfertas(ofertasData.ofertas);
            setOfertasFiltered(ofertasData.ofertas);
            setPage(ofertas.paginaActual || currentPage);
            setPageCount(ofertasData.totalPages);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            showErrorToast('Error al obtener trámites:', error);
        }
    };

    useEffect(() => {
        fetchData(activeTab === "pendientes" ? 0 : 1, currentPage); // Fetch data based on active tab
    }, [activeTab]); // Update data on tab change

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPage(1); // Reset page number on tab change
    };

    const handleLinkBaseClick = async (event, id) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        try {
            const dataDoc = await APICompras.resultadoPdf(id);
            // Crear una URL de datos con el contenido base64
            const pdfDataUrl = `data:application/pdf;base64,${dataDoc?.Documentos[0]?.base64}`;
            setDataUrl([pdfDataUrl]);
            setViewerOpen(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const formatDate = (dateString) => {
        if (dateString) {
            return format(new Date(dateString), 'dd-MM-yyyy'); // Customize format as needed
        }
        return '';
    };

    const getRows = (listOfertas) => {

        return listOfertas.map((oferta, index) => {
            return <tr key={`oferta-${index}`}>
                <td style={{ width: "2%", fontSize: "0.9rem" }}>
                    {oferta.id}
                </td>
                <td title={oferta.AccidentNumber} style={{ width: "5%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "120px" }}>{oferta.AccidentNumber}</td>
                <td style={{ width: "5%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>{oferta.OrderNumber}</td>
                <td style={{ width: "3%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.AccidentDate ? format(new Date(oferta.AccidentDate), 'dd/MM/yyyy') : 'Fecha Desconocida'}</td>
                <td style={{ width: "4%", fontSize: "78%", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "150px" }}>
                    {oferta?.ChassisNumber ? oferta?.ChassisNumber : 'Bastidor Desconocido'}
                </td>
                <td style={{ width: "3%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.RegistrationDate ? format(new Date(oferta.RegistrationDate), 'dd/MM/yyyy') : 'Fecha Desconocida'}</td>
                <td style={{ width: "5%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "140px" }}>{oferta.VehicleRegistration}</td>
                <td title={oferta.VehicleBrand ? oferta.VehicleBrand : 'Marca Desconocida'} style={{ width: "7%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>{oferta.VehicleBrand ? oferta.VehicleBrand : 'Marca Desconocida'}</td>
                <td title={oferta.Model ? oferta.Model : 'Modelo Desconocido'} style={{ width: "9%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.Model ? oferta.Model : 'Modelo Desconocido'}</td>
                <td title={oferta.WorkshopLocation} style={{ width: "8%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.WorkshopLocation}</td>
                <td style={{ width: "4%", fontSize: "0.9rem" }}>
                    <input style={{ width: "4rem" }} id={oferta.id + "#BidValue"} type="number" placeholder='Importe €'
                        defaultValue={oferta?.BidValue}
                        disabled={oferta.CompletionDate != null || oferta.RejectedDate != null} />
                </td>
                <td style={{ width: "3%", fontSize: "0.9rem" }}>
                    {getBtns(oferta)}
                </td>
            </tr >
        })
    }

    const getCards = (listOfertas) => {
        return listOfertas.map((oferta, index) => {
            return <div key={`oferta-${index}`} className='ofertas__card'>
                        <div className='ofertas__card--titles'><span>Id</span><span>Siniestro</span><span>Expediente</span></div>
                        <div className='ofertas__card--text'>
                            <span>{oferta.id}</span>
                            <span>{oferta.AccidentNumber}</span>
                            <span>{oferta.OrderNumber}</span>
                        </div>
                        <div className='ofertas__card--titles second'><span>Fecha Sin.</span><span>Bastidor</span></div>
                        <div className='ofertas__card--text second'>
                            <span>{oferta.AccidentDate ? format(new Date(oferta.AccidentDate), 'dd/MM/yyyy') : 'Fecha Desconocida'}</span>
                            <span>{oferta?.ChassisNumber ? oferta?.ChassisNumber : 'Bastidor Desconocido'}</span>
                        </div>
                        <div className='ofertas__card--titles'><span>Fecha Mat.</span><span>Matrícula</span><span>Marca</span></div>
                        <div className='ofertas__card--text'>
                            <span>{oferta.RegistrationDate ? format(new Date(oferta.RegistrationDate), 'dd/MM/yyyy') : 'Fecha Desconocida'}</span>
                            <span>{oferta.VehicleRegistration}</span>
                            <span>{oferta.VehicleBrand ? oferta.VehicleBrand : 'Marca Desconocida'}</span>
                        </div>
                        <div className='ofertas__card--titles'><span>Modelo</span><span>Localidad</span></div>
                        <div className='ofertas__card--text'>
                            <span>{oferta.Model ? oferta.Model : 'Modelo Desconocido'}</span>
                            <span>{oferta.WorkshopLocation}</span>
                            <span className='ofertas__card--text--input'>
                                <input id={oferta.id + "#BidValue"} type="number" placeholder='Importe €'
                                    defaultValue={oferta?.BidValue}
                                    disabled={oferta.CompletionDate != null || oferta.RejectedDate != null} />
                            </span>
                        </div>
                        {getBtns(oferta)}
                    </div >
        })
    }

    const getBtns = (oferta) => {
        return <div className='ofertas__card--icons'>
            {oferta.BidValue != null || oferta.BidValueDate != null || oferta.RejectedDate != null ? (
                    <span className='ofertas__card--icons--pdf' onClick={(event) => handleLinkBaseClick(event, oferta.id)}>
                        <TooltipIcon title='Descargar Pdf' icon={faFilePdf} />
                    </span>
                ) : (
                    <span className='ofertas__card--icons--clock'>
                        <TooltipIcon 
                            title={oferta.CallDate ? `La oferta finaliza el: ` + formatDate(oferta.CallDate) : 'No existe fecha de fin especifica'} 
                            icon={faClock} />
                    </span>
                )}
            <button
                className='ofertas__card--icons--offer'
                onClick={(event) => handleOferta(event, oferta)}
                disabled={oferta.CompletionDate != null || oferta.RejectedDate != null}
            >
                <TooltipIcon title='Oferta' icon={faFileLines} />
            </button>
            <button
                className='ofertas__card--icons--cancel' 
                onClick={(event) => handleRechazarOferta(event, oferta)}
                disabled={oferta.CompletionDate != null || oferta.RejectedDate != null}
            >
                <TooltipIcon title='Rechazar' icon={faCircleXmark} />
            </button>
            <button
                className='ofertas__card--icons--info' 
                onClick={(event) => handleInfoVehiculoClick(event, oferta)}
            >
                <TooltipIcon title='+ Info' icon={faCircleInfo} />
            </button>
        </div>
    }
    
    return (
        <div className="content pt-3 ofertas">
            {isInfoVehiculoDialog && <InfoVehiculoDialog vehicleInfo={selectedOferta} state={isInfoVehiculoDialog} onClose={() => { setIsInfoVehiculoDialog(false) }} />}
            <ToastContainer 
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable />
            <h1>Compra de Vehículos FIATC</h1>
            <div className="tabs">
                <button onClick={() => handleTabChange("pendientes")} className={`tabs__tab ${activeTab === "pendientes" ? "tabs__tab--selected" : ""}`}>
                        Pendientes
                </button>
                <button onClick={() => handleTabChange("finalizadas")} className={`tabs__tab ${activeTab === "finalizadas" ? "tabs__tab--selected" : ""}`}>
                        Finalizadas
                </button>
            </div>
            <div className="w-100 ofertas-list-item">
                <div className="table-responsive">
                    {!isLoading
                        ? screenWidth < 992 
                            ? <div className='ofertas__container'>
                                    {ofertas !== null && ofertas.length > 0 ? (
                                            getCards(ofertas)
                                        ) : (
                                            <div>
                                                <span>
                                                    No hay ofertas disponibles
                                                </span>
                                            </div>
                                        )}
                                </div>
                            : <table className="table mb-4">
                                <thead>
                                <tr>
                                    <ColumnHeader
                                        title="Id"
                                    />
                                    <ColumnHeader
                                        title="Siniestro"
                                    />
                                    <ColumnHeader
                                        title="Expediente"
                                    />
                                    <ColumnHeader
                                        title="Fecha Sin."
                                    />
                                    <ColumnHeader
                                        title="Bastidor"
                                    />
                                    <ColumnHeader
                                        title="Fecha Mat."
                                    />
                                    <ColumnHeader
                                        title="Matrícula"
                                    />
                                    <ColumnHeader
                                        title="Marca"
                                    />
                                    <ColumnHeader
                                        title="Modelo"
                                    />
                                    <ColumnHeader
                                        title="Localidad"
                                    />
                                    <ColumnHeader
                                        title=""
                                    />
                                    <ColumnHeader
                                        title=""
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {ofertas !== null && ofertas.length > 0 ? (
                                    getRows(ofertas)
                                ) : (
                                    <tr>
                                        <td colSpan="13">
                                            No hay ofertas disponibles
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : <><SkeletonEachResult /><SkeletonEachResult /><SkeletonEachResult /></>}
                        
                    <Pagination
                        className="mt-3"
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            </div>
            <ViewerDialog
                dataUrl={dataUrl}
                open={viewerOpen}
                onClose={handleCloseViewer}
            />
        </div>
    );
}
