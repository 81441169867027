import React, { memo } from "react";
import { useState } from "react";
import { isEqual } from 'lodash';
import { SidebarData } from "./SidebarData";
import './Navbar.scss';
import logo from '../../../../../../images/logo_white.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import UserAvatar from "../Navbar/components/UserAvatar";

const Navbar = ({
    onLogout,
}) => {
    let pathname = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [sideBar, setSidebar] = useState(false);

    const handleClickMenuItem = (path) => { navigate(path); }
    const showSidebar = (show) => { setSidebar(show); }

    const NavItem = memo(({ children }) => {
        return (
            <span>
                {children}
            </span>
        )
    }, isEqual)

    return (
        <>
            <div className="navbar-sidebar">
                <Link to="#" className="menu-bars" onMouseEnter={() => showSidebar(true)}>
                    <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
                </Link>
                {(() => {
                    switch (currentUser?.rol_id) {
                        case 4:
                            return (
                                <span style={{marginRight : '2rem' }}> Compra de Vehículos
                                </span>
                            );
                        case 3:
                            return (
                                <span style={{marginRight : '2rem' }}> Desguace
                                </span>
                            );
                        case 2:
                            return (
                                <span style={{marginRight : '2rem' }}> Concesionario
                                </span>
                            );
                        case 1:
                            return (
                                <span style={{marginRight : '2rem' }}> Administrador
                                </span>
                            );
                        default:
                            return null;
                    }
                })()}
            </div>

            <nav className={sideBar ? "nav-menu active" : "nav-menu"} onMouseLeave={() => showSidebar(false)}>
                <ul className="nav-menu-items">
                    <li className="mb-3 nav-menu-items__header">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="navbar-brand">
                                <img src={logo} className="brand" style={{ height: '60px' }} alt="Recomotor" />
                            </div>
                            {<UserAvatar style={{ cursor: 'pointer' }} onLogout={onLogout} />}
                        </div>
                    </li>
                    {SidebarData.map((item, index) => {
                        if (
                            (currentUser?.rol_id === 4 && item.permission === 4) ||
                            (item.permission === 0 && currentUser?.rol_id !== 4) ||
                            item.permission === currentUser?.rol_id ||
                            (currentUser?.rol_id === 1 || currentUser?.rol_id === 7)
                        ) {
                            return (
                                <NavItem key={index}>
                                    <span
                                        onClick={() => handleClickMenuItem(item.path)}
                                        className={`${item.cName} 
                                        ${item.title.toLowerCase() === pathname ? "active" : ""}`}>
                                        <li style={{ cursor: 'pointer' }}>
                                            <span>{item.title}</span>
                                        </li>
                                    </span>
                                </NavItem>
                            )
                        } else {
                            return '';
                        }
                    })}

                </ul>
            </nav>
        </>
    );
}

export default memo(Navbar)