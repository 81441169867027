import API_ZEUS from '../../index';

export const APICompras = {
    obtenerDatosOfertas: async (body) => {
        return await API_ZEUS.post('/integration/obtenerDatosOfertas', body).then(res => res.data);
    },
    resultadoOferta: async (body) => {
        return await API_ZEUS.post('/integration/resultadoOferta', body).then(res => res.data);
    },
    rechazarOferta: async (body) => {
        return await API_ZEUS.post('/integration/rechazarOferta', body).then(res => res.data);
    },
    resultadoPdf: async (id) => {
        return await API_ZEUS.get(`/integration/recogerPdf?IDOffer=${id}`).then(res => res.data);
    },
    recogerImagenes: async (id) => {
        return await API_ZEUS.get(`/integration/recogerImagenes?IDOffer=${id}`).then(res => res.data);
    },
};