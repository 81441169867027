import API from '../../index';

export const APITramites = {
    post: async (body) => {
        return await API.post('/tramites/getTramitesFiltered', body).then(res => res.data);
    },
    excelTramites: async (body) => {
        return await API.post('/tramites/getExcelTramites', body).then(res => res.data);
    },
    create: async (body) => {
        return await API.post('/tramites', body).then(res => res.data);
    },
    update: async (tramiteId, body) => {
        return await API.put(`/tramites/${tramiteId}`, body).then(res => res.data);
    },
    updatePrecio: async (tramiteId, body) => {
        return await API.put(`/tramites/precio/${tramiteId}`, body).then(res => res.data);
    },
    updateValidado: async (tramiteId, body) => {
        return await API.put(`/tramites/validado/${tramiteId}`, body).then(res => res.data);
    }
};