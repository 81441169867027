import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIConcesionarios } from "../../../../services/api/bajas/concesionarios";
import { APIEmpresas } from "../../../../services/api/bajas/empresas";
import { APIPoblaciones } from "../../../../services/api/bajas/poblaciones";
import { APIUsuarios } from "../../../../services/api/bajas/usuarios";

export default function Concesionarios({ company, isEdit, onSubmitCompany }) {
  const [poblaciones, setPoblaciones] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [selectedValuePoblaciones, setSelectedValuePoblaciones] = useState(null);
  const [selectedValueEmpresas, setSelectedValueEmpresas] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedValuePersonas, setSelectedValuePersonas] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedValueConcesionarios, setSelectedValueConcesionarios] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedConcesionario, setSelectedConcesionario] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      nombre: "",
      direccion: "",
      id_poblacion: "",
      codigo_postal: "",
      email: "",
      telefono: "",
      id_empresa: "",
      id_persona_contacto: "",
    },
  });

  const fetchDataPoblaciones = async (inputValue) => {
    try {
      const dataPoblaciones = await APIPoblaciones.get(inputValue);
      setPoblaciones(dataPoblaciones.poblaciones);
    } catch (error) {
      console.error('Error al obtener poblaciones:', error);
    }
  };

  const fetchDataEmpresas = async (inputValue) => {
    try {
      const dataEmpresas = await APIEmpresas.get(inputValue);
      setEmpresas(dataEmpresas.empresas);
    } catch (error) {
      console.error('Error al obtener empresas:', error);
    }
  };

  const fetchDataUsuarios = async (inputValue) => {
    try {
      let dataUsuarios = null;
      if (inputValue) {
        dataUsuarios = await APIUsuarios.get(inputValue);
      }else{
        dataUsuarios = await APIUsuarios.get('-1',JSON.parse(localStorage.getItem('user'))?.id_empresa);
      }
      setPersonas(dataUsuarios.usuarios);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    }
  };

  const isFormReadOnly = JSON.parse(localStorage.getItem('user'))?.rol_id !== 1;

  // Función para aplicar readOnly a los inputs y deshabilitar los botones
  const applyReadOnly = (element) => {
    if (element.type === 'text' || element.type === 'textarea') {
      element.readOnly = isFormReadOnly;
    } else if (element.type === 'button' || element.type === 'submit') {
      element.disabled = isFormReadOnly;
    } else if (element.classList.contains('MuiAutocomplete-input')) {
      // Para el input dentro de Autocomplete
      element.readOnly = isFormReadOnly;
    }
  };

  // Función para recorrer y aplicar a los elementos del formulario
  const applyReadOnlyToForm = (form) => {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      applyReadOnly(formElements[i]);
    }
  };

  const fetchDataConcesionarios = async () => {
    try {
      let dataConcesionarios = null;
      const id_concesionario = JSON.parse(localStorage.getItem('user'))?.id_concesionario;
      if (id_concesionario) {
        dataConcesionarios = isFormReadOnly
          ? await APIConcesionarios.getById(id_concesionario)
          : await APIConcesionarios.get();
      } else {
        const id_empresa = JSON.parse(localStorage.getItem('user'))?.id_empresa;
        dataConcesionarios = isFormReadOnly
          ? await APIConcesionarios.getByEmpresaId(id_empresa)
          : await APIConcesionarios.get();
        dataConcesionarios.concesionarios = dataConcesionarios.concesionario;
      }
      const concesionarios = dataConcesionarios.concesionarios ? dataConcesionarios.concesionarios : [dataConcesionarios.concesionario];
      setConcesionarios(concesionarios);
    } catch (error) {
      console.error('Error al obtener empresa:', error);
    }
  };

  const handleEliminarClick = async (id) => {
    try {
      await APIConcesionarios.delete(id);
      showToast('Concesionario eliminado con éxito');
      fetchDataConcesionarios();
      reset({
        nombre: "",
        direccion: "",
        id_poblacion: "",
        codigo_postal: "",
        email: "",
        telefono: "",
        id_empresa: "",
        id_persona_contacto: "",
      });
      setSelectedConcesionario(null);
      return;
    } catch (err) {
      showErrorToast('Error al eliminar el concesionario. Por favor, inténtalo de nuevo más tarde.');
      return;
    }
  };

  useEffect(() => {
    if (selectedConcesionario) {
      reset(selectedConcesionario);
    } else {
      reset();
    }
    fetchDataConcesionarios();
    //usamos por el momento el id_concesionario del usuario conectado
    //los cambios mas profundos seran a demanda desde concesionario.
    fetchDataUsuarios(JSON.parse(localStorage.getItem('user'))?.id_concesionario);
  }, [selectedConcesionario]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log(data);
    const documentoForm = new FormData();
    try {
      if (selectedConcesionario) {
        // Si hay un concesionario seleccionado, actualiza
        const res = await APIConcesionarios.update(selectedConcesionario.id, data);
        showToast('Concesionario actualizado con éxito');
      } else {
        // Si no hay Concesionario seleccionado, crea uno nuevo
        const res = await APIConcesionarios.create(data);
        showToast('Concesionario creado con éxito');
      }
      // Después de crear o actualizar, puedes limpiar el formulario
      reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        email: "",
        telefono: "",
        password: "",
        id_empresa: null,
        id_concesionario: null,
      });
      setSelectedConcesionario(null);
      setSelectedValueEmpresas(null);
      setSelectedValuePoblaciones(null);
      setSelectedValuePersonas(null);
      fetchDataConcesionarios();
    } catch (err) {
      showErrorToast('Error al procesar la operación. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  const handleInputPoblacionesChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    if (newInputValue.length > 2 && newInputValue !== '') {
      fetchDataPoblaciones(newInputValue);
    }
  };

  const handlePoblacionesChange = (event, newValue) => {
    setSelectedValuePoblaciones(newValue);
    setValue('id_poblacion', newValue ? newValue.id : null);
    setValue('codigo_postal', newValue ? newValue.codigo_postal : null);
  };

  const handleInputEmpresasChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    fetchDataEmpresas(newInputValue);
  };

  const handleEmpresasChange = (event, newValue) => {
    setSelectedValueEmpresas(newValue);
    setValue('id_empresa', newValue ? newValue.id : null);
  };

  const handlePersonasChange = (event, newValue) => {
    setSelectedValuePersonas(newValue);
    setValue('id_persona_contacto', newValue ? newValue.id : null);
  };

  const showToast = (message) => {
    toast.info(message, {
      toastId: "info-movs",
      position: 'top-right', // You can customize the position
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-right', // You can customize the position
    });
  };

  const handleTableRowClick = (concesionario) => {
    setSelectedConcesionario(concesionario);
    setValue('id_concesionario', concesionario ? concesionario.id : null);
    setSelectedValuePoblaciones(concesionario.id_poblacion_bv_poblacione);
    setSelectedValueEmpresas(concesionario.id_empresa_bv_empresa);
    setSelectedValuePersonas(concesionario.id_persona_contacto_bv_usuario);
    setValue('id_empresa', concesionario ? concesionario.id_empresa : null);
  };

  return (
    <div className="content pt-3 facturas">
      <ToastContainer />
      <h1>Datos Concesionario</h1>
      <div className='facturas__table-container table-responsive'>
        <table className="table table-bordered table-striped">
          <thead className="bg-secondary text-white">
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Población</th>
              <th>CodigoPostal</th>
              <th>Email Administración</th>
              <th>Teléfono</th>
              <th>Empresa</th>
              <th>Persona Contacto</th>
            </tr>
          </thead>
          <tbody>
            {concesionarios.map((concesionario) => (
              <tr
                key={concesionario.id}
                onClick={() => handleTableRowClick(concesionario)}
                className="cursor-pointer"
              >
                <td>{concesionario.nombre}</td>
                <td>{concesionario.direccion}</td>
                <td>{concesionario.id_poblacion_bv_poblacione.poblacion}</td>
                <td>{concesionario.codigo_postal}</td>
                <td>{concesionario.email}</td>
                <td>{concesionario.telefono}</td>
                <td>{concesionario.id_empresa_bv_empresa.nombre_fiscal}</td>
                <td>{concesionario.id_persona_contacto_bv_usuario?.nombre}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='facturas__container-form'>
        <div className='facturas__container-form--form-box'>
          <p className="mb-2 facturas__title-inputs">Introduzca o actualice los datos del concesionario</p>
          <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100 d-flex flex-wrap empresa-form">
              <div className="form-group">
                <label htmlFor="nombre">Nombre Fiscal</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("nombre", { required: true, maxLength: 50 })}
                />
                <div className="form-invalid">
                  {errors.nombre?.type === "required" && "Nombre es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Dirección</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("direccion", { required: true })}
                />
                <div className="form-invalid">
                  {errors.direccion?.type === "required" && "Dirección es obligatoria"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="id_poblacion">Población</label>
                <Autocomplete
                  options={poblaciones}
                  getOptionLabel={(option) => option.poblacion}
                  onChange={handlePoblacionesChange}
                  onInputChange={handleInputPoblacionesChange}
                  value={selectedValuePoblaciones}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba al menos 3 letras para encontrar su población"
                />
                <div className="form-invalid">
                  {errors.selectedValuePoblaciones?.type === "required" && "Población es obligatoria"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="codigo_postal">Código Postal</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("codigo_postal")}
                />
                {/* Add error handling for cp if needed */}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Administración</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("email", { required: true })}
                />
                <div className="form-invalid">
                  {errors.email?.type === "required" && "Email es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("telefono")}
                />
                {/* Add error handling for telefono if needed */}
              </div>
              <div className="form-group">
                <label htmlFor="id_empresa">Empresa</label>
                <Autocomplete
                  options={empresas}
                  getOptionLabel={(option) => option.nombre_fiscal}
                  onChange={handleEmpresasChange}
                  onInputChange={handleInputEmpresasChange}
                  value={selectedValueEmpresas}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba para encontrar su empresa"
                />
                <div className="form-invalid">
                  {errors.selectedValueEmpresas?.type === "required" && "Población es obligatoria"}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="id_persona_contacto">Persona de contacto</label>
                <Autocomplete
                  options={personas}
                  getOptionLabel={(option) => option.nombre + " " + option.apellido1}
                  onChange={handlePersonasChange}
                  value={selectedValuePersonas}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                    />
                  )}
                  noOptionsText="Escriba para encontrar su contacto"
                />
              </div>
            </div>

            <div className="w-100 d-flex flex-wrap facturas__btn-container">
              {selectedConcesionario && (
                <button type="button"
                  className="btn btn-secondary align-self-end ml-2"
                  onClick={() => {
                    reset({
                      nombre: "",
                      direccion: "",
                      id_poblacion: "",
                      codigo_postal: "",
                      email: "",
                      telefono: "",
                      id_empresa: "",
                      id_persona_contacto: "",
                    });
                    setSelectedConcesionario(null);
                    setSelectedValueEmpresas(null);
                    setSelectedValuePoblaciones(null);
                    setSelectedValuePersonas(null);
                  }}>
                  Reset
                </button>
              )}
              <button type="submit" className="btn btn-primary align-self-end">
                {selectedConcesionario ? "Actualizar" : "Crear"}
              </button>
              {selectedConcesionario && !isFormReadOnly && (
                <button
                  type="button"
                  className="btn btn-secondary align-self-end ml-2"
                  onClick={() => handleEliminarClick(selectedConcesionario.id)}
                >
                  Eliminar
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}