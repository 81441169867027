import API from '../../index';

export const APIDocumentacion = {
    generatePdf: async (body) => {
        return await API.post('/documentacion/generatePdf', body).then(res => res.data);
    },
    getPdf: async (body) => {
        return await API.post('/documentacion/getPdf', body).then(res => res.data);
    },
    getDoc: async (body) => {
        return await API.post('/documentacion/getDoc', body).then(res => res.data);
    },
    getAllDocs: async (body) => {
        return await API.post('/documentacion/getAllDocs', body).then(res => res.data);
    },
};