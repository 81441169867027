import API from '../../index';
import { headers } from '../../../../views/shared/helpers/functionalities';

export const APIFacturas = {
    get: async () => {
        return await API.get('/facturas').then(res => res.data);
    },
    getById: async (facturaId) => {
        return await API.get(`/facturas/${facturaId}`).then(res => res.data);
    },
    create: async (documento) => {
        return await API.post('/facturas', documento, headers).then(res => res.data);
    },
    update: async (facturaId, body) => {
        return await API.put(`/facturas/${facturaId}`, body, headers).then(res => res.data);
    },
    delete: async (facturaId) => {
        return await API.delete(`/facturas/${facturaId}`).then(res => res.data);
    },
};
