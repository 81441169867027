export const SidebarData = [
    {
        title: 'Histórico Bajas',
        path: '/',
        cName: 'nav-text',
        permission: 0
    },{
        title: 'Facturas',
        path: '/facturas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Datos Empresa',
        path: '/datos-empresa',
        cName: 'nav-text',
        permission: 2
    },
    {
        title: 'Datos Concesionario',
        path: '/datos-concesionario',
        cName: 'nav-text',
        permission: 2
    },
    {
        title: 'Datos Usuario',
        path: '/datos-usuario',
        cName: 'nav-text',
        permission: 2
    },
    {
        title: 'Datos Contacto Desguace',
        path: '/datos-desguace',
        cName: 'nav-text',
        permission: 3
    },
    {
        title: 'Compra Vehículos FIATC',
        path: '/compra-fiatc',
        cName: 'nav-text',
        permission: 4
    },
    {
        title: 'Compra Vehículos Mutua',
        path: '/compra-mutua',
        cName: 'nav-text',
        permission: 4
    },
    {
        title: 'Compra Vehículos Wallapop',
        path: '/compra-wallapop',
        cName: 'nav-text',
        permission: 4
    }
]