import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import { format } from 'date-fns'; // Install date-fns library: npm install date-fns
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewerDialog from "../../HistoricoBajas/Dialogs/ViewerDialog";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SwiperSlider from '../../shared/components/CompraVehiculos/SwiperSlider';
import { APICompras } from "../../../../../services/api_zeus/compras/ofertas/";

export default function InfoVehiculo({
    onConfirmAction,
    onClose,
    state,
    vehicleInfo, // New prop to receive information
}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({});

    const [viewerOpen, setViewerOpen] = useState(false);
    const [dataUrl, setDataUrl] = useState(null);

    const vehicleInfoFields = [
        { name: 'AccidentNumber', label: 'Accident Number' },
        { name: 'OrderNumber', label: 'Order Number' },
        { name: 'AccidentDate', label: 'Accident Date', type: 'date' },
        { name: 'PostalCode', label: 'Postal Code' },
        { name: 'RegistrationDate', label: 'Registration Date', type: 'date' },
        { name: 'VehicleRegistration', label: 'Vehicle Registration' },
        { name: 'VehicleBrand', label: 'Vehicle Brand' },
        { name: 'Model', label: 'Model' },
        { name: 'Version', label: 'Version' },
        { name: 'ChassisNumber', label: 'Chassis Number' },
        { name: 'FuelType', label: 'Fuel Type' },
        { name: 'Mileage', label: 'Mileage', type: 'number' },
        { name: 'ApproximatedValue', label: 'Approximated Value', type: 'number' },
        { name: 'ImpactDescription', label: 'Impact Description', type: 'text' },
        { name: 'WorkshopName', label: 'Workshop Name' },
        { name: 'WorkshopAddress', label: 'Workshop Address' },
        { name: 'WorkshopLocation', label: 'Workshop Location' },
        { name: 'Color', label: 'Color' },
        { name: 'StateVehicle', label: 'State Vehicle' },
        { name: 'Power', label: 'Power', type: 'number' },
        { name: 'Displacement', label: 'Displacement', type: 'number' },
        { name: 'Doors', label: 'Doors', type: 'number' },
        { name: 'VehicleType', label: 'Vehicle Type' },
        { name: 'VehicleEquipment', label: 'Vehicle Equipment', type: 'text' },
        { name: 'VenalValue', label: 'Venal Value', type: 'number' },
        { name: 'BidValue', label: 'Bid Value', type: 'number' },
        { name: 'CompletionDate', label: 'Completion Date', type: 'date' },
        { name: 'BidDate', label: 'Bid Date', type: 'date' },
        { name: 'BidValidDate', label: 'Bid Valid Date', type: 'date' }
    ];

    const [images, setImages] = useState([]); // Initialize images state as an empty array

    useEffect(() => {
        // Fetch image data on component mount or vehicleInfo.id change
        const fetchData = async () => {
            try {
                const ofertasData = await APICompras.recogerImagenes(vehicleInfo.id);
                ofertasData.Imagenes = ofertasData?.Imagenes ? ofertasData.Imagenes.split(",") : [];
                const formattedImages = ofertasData.Imagenes.map((image) => ({
                    original: `data:image/png;base64,${image}`,
                    thumbnail: `data:image/png;base64,${image}`,
                }));
                setImages(formattedImages || []); // Set images state with formatted data or empty array
            } catch (error) {
                console.error("Error fetching image data:", error);
                // Handle error appropriately (e.g., display an error message)
            }
        };
        fetchData();
    }, [vehicleInfo.id, vehicleInfo]);

    const handleConfirm = async (confirm) => {
        try {
            onClose();
            onConfirmAction(confirm);
        } catch (error) {
            showErrorToast('Error al crear la baja. Por favor, inténtalo de nuevo más tarde.');
        }
    };

    const handleCloseViewer = () => {
        setViewerOpen(false);
    };

    const handleClose = () => {
        onClose();
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const formatDate = (dateString) => {
        if (dateString) {
            return format(new Date(dateString), 'dd-MM-yyyy'); // Customize format as needed
        }
        return '';
    };

    return (
        <Dialog
            open={state}
            onClose={onClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description"
            maxWidth="md">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fs-4 pt-3">Información ampliada del vehículo</p>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="scroll-container">
                <DialogContentText id="confirm-description">
                    <br />
                    <form>
                        <Grid container spacing={2}>
                            {/* ... form fields rendered using vehicleInfoFields */}
                            {vehicleInfoFields.map((field, index) => (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <TextField
                                        {...register(field.name, { required: true })}
                                        label={field.label}
                                        value={field.type === 'date' ? formatDate(vehicleInfo[field.name]) : vehicleInfo[field.name]} // Parse date if type is 'date'
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        fullWidth
                                        disabled
                                        type={'text'} // Set type based on field type
                                    />
                                </Grid>
                            ))}

                            <SwiperSlider images={images} />
                                
                        </Grid>
                    </form>
                    {/* Your ViewerDialog component (if needed) */}
                    <ViewerDialog /* ... props for viewer dialog */ />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}