import API from '../../index';

export const APIEmpresas = {
    get: async () => {
        return await API.get('/empresas').then(res => res.data);
    },
    getById: async (empresaId) => {
        return await API.get(`/empresas/${empresaId}`).then(res => res.data);
    },
    create: async (body) => {
        return await API.post('/empresas', body).then(res => res.data);
    },
    update: async (empresaId, body) => {
        return await API.put(`/empresas/${empresaId}`, body).then(res => res.data);
    },
    delete: async (empresaId) => {
        return await API.delete(`/empresas/${empresaId}`).then(res => res.data);
    },
};