import API from '../../index';

export const APIUsuarios = {
    get: async (id_concesionario, id_empresa) => {
        return await API.get(`/usuarios/${id_concesionario}/${id_empresa}`).then(res => res.data);
    },    
    getById: async (usuarioId) => {
        return await API.get(`/usuarios/${usuarioId}`).then(res => res.data);
    },
    getByEmpresaId: async (empresaId) => {
        return await API.get(`/usuarios/empresa/${empresaId}`).then(res => res.data);
    },
    create: async (body) => {
        // Agrega aquí la lógica necesaria para hashear la contraseña antes de enviarla al servidor si es necesario
        return await API.post('/usuarios', body).then(res => res.data);
    },
    update: async (usuarioId, body) => {
        return await API.put(`/usuarios/${usuarioId}`, body).then(res => res.data);
    },
    delete: async (usuarioId) => {
        return await API.delete(`/usuarios/${usuarioId}`).then(res => res.data);
    },
};
