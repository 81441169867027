import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import { APIEmpresas } from "../../../../services/api/bajas/empresas";
import { APIPoblaciones } from "../../../../services/api/bajas/poblaciones";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Empresa({ company, isEdit, onSubmitCompany }) {
  const [poblaciones, setPoblaciones] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      nombre_fiscal: "",
      cif: "",
      direccion: "",
      codigo_postal: "",
      email: "",
      telefono: "",
    },
  });
  const isFormReadOnly = JSON.parse(localStorage.getItem('user'))?.rol_id !== 1;

  const fetchData = async (inputValue) => {
    try {
      const dataPoblaciones = await APIPoblaciones.get(inputValue);
      setPoblaciones(dataPoblaciones.poblaciones);
    } catch (error) {
      console.error('Error al obtener poblaciones:', error);
    }
  };

  // Función para aplicar readOnly a los inputs y deshabilitar los botones
  const applyReadOnly = (element) => {
    if (element.type === 'text' || element.type === 'textarea') {
      element.readOnly = isFormReadOnly;
    } else if (element.type === 'button' || element.type === 'submit') {
      element.disabled = isFormReadOnly;
    }
  };

  // Función para recorrer y aplicar a los elementos del formulario
  const applyReadOnlyToForm = (form) => {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      applyReadOnly(formElements[i]);
    }
  };

  const fetchDataEmpresa = async () => {
    try {
      const dataEmpresas = isFormReadOnly
        ? await APIEmpresas.getById(JSON.parse(localStorage.getItem('user'))?.id_empresa)
        : await APIEmpresas.get();
  
      const empresas = dataEmpresas.empresas ? dataEmpresas.empresas : [dataEmpresas.empresa];
  
      // Verificar si hay una empresa seleccionada antes de llamar a reset
      if (!selectedEmpresa || selectedEmpresa.length === 0) {
        reset({
          nombre_fiscal: empresas[0].nombre_fiscal,
          cif: empresas[0].cif,
          direccion: empresas[0].direccion,
          codigo_postal: empresas[0].codigo_postal,
          email: empresas[0].email,
          telefono: empresas[0].telefono,
        });
  
        setSelectedValue(empresas[0].id_poblacion_bv_poblacione);
        setValue('id_poblacion', empresas[0].id_poblacion_bv_poblacione ? empresas[0].id_poblacion_bv_poblacione.id : null);
      }
  
      setEmpresas(empresas);
    } catch (error) {
      console.error('Error al obtener empresa:', error);
    }
  };

  useEffect(() => {
    setSelectedEmpresa(null);
    setSelectedValue(null);  
    fetchDataEmpresa();
  }, []);

  const handleInputChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    if (newInputValue.length > 2 && newInputValue !== '') {
      fetchData(newInputValue);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    setValue('id_poblacion', newValue ? newValue.id : null);
    setValue('codigo_postal', newValue ? newValue.codigo_postal : null);
  };

  const handleTableRowClick = (empresa) => {
    reset({
      nombre_fiscal: empresa.nombre_fiscal,
      cif: empresa.cif,
      direccion: empresa.direccion,
      codigo_postal: empresa.codigo_postal,
      email: empresa.email,
      telefono: empresa.telefono,
    });
    setSelectedEmpresa(empresa);
    setSelectedValue(empresa.id_poblacion_bv_poblacione);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const documentoForm = new FormData();
    try {
      if (selectedEmpresa) {
        // Si hay empresa seleccionada, actualiza
        const res = await APIEmpresas.update(selectedEmpresa.id, data);
        showToast('Empresa actualizada con éxito');
      } else {
        // Si no hay empresa seleccionada, crea una nueva
        const res = await APIEmpresas.create(data);
        showToast('Empresa creada con éxito');
      }
      // Después de crear o actualizar, puedes limpiar el formulario
      reset({
        nombre_fiscal: "",
        cif: "",
        direccion: "",
        codigo_postal: "",
        email: "",
        telefono: "",
      });
      setSelectedValue(null);
      fetchDataEmpresa();
    } catch (err) {
      showErrorToast('Error al crear la empresa. Por favor, inténtalo de nuevo más tarde.');
      return;
    }
  };

  const showToast = (message) => {
    toast.info(message, {
      toastId: "info-movs",
      position: 'top-right', // You can customize the position
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-right', // You can customize the position
    });
  };

  return (
    <div className="content pt-3 facturas">
      <ToastContainer />
      <h1>Datos Empresa</h1>
      <div className='facturas__table-container table-responsive'>
        <table className="table table-bordered table-striped">
          <thead className="bg-secondary text-white">
            <tr>
              <th>Nombre Fiscal</th>
              <th>NIF</th>
              <th>Dirección</th>
              <th>Código Postal</th>
              <th>Email</th>
              <th>Teléfono</th>
            </tr>
          </thead>
          <tbody>
            {empresas.map((empresa) => (
              <tr
                key={empresa.id}
                onClick={() => handleTableRowClick(empresa)}
                className="cursor-pointer"
              >
                <td>{empresa.nombre_fiscal}</td>
                <td>{empresa.cif}</td>
                <td>{empresa.direccion}</td>
                <td>{empresa.codigo_postal}</td>
                <td>{empresa.email}</td>
                <td>{empresa.telefono}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='facturas__container-form'>
        <div className='facturas__container-form--form-box'>
          <p className="mb-2 facturas__title-inputs">Introduzca o actualice los datos de empresa</p>
          <form className="w-100 form" ref={(form) => form && applyReadOnlyToForm(form)} onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100 d-flex flex-wrap empresa-form">
              <div className="form-group">
                <label htmlFor="nombre_fiscal">Nombre Fiscal</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("nombre_fiscal", { required: true, maxLength: 50 })}
                  defaultValue={""}
                />
                <div className="form-invalid">
                  {errors.nombre_fiscal?.type === "required" && "Nombre fiscal es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="cif">CIF</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("cif", { required: true })}
                  defaultValue={isEdit ? company.cif : ""}
                />
                <div className="form-invalid">
                  {errors.cif?.type === "required" && "CIF es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Dirección</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("direccion", { required: true })}
                  defaultValue={""}
                />
                <div className="form-invalid">
                  {errors.direccion?.type === "required" && "Dirección es obligatoria"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="id_poblacion">Población</label>
                <Autocomplete
                  disabled={isFormReadOnly}
                  options={poblaciones}
                  getOptionLabel={(option) => option.poblacion}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  value={selectedValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba al menos 3 letras para encontrar su población"
                />
                <div className="form-invalid">
                  {errors.selectedValue?.type === "required" && "Población es obligatoria"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="codigo_postal">Código Postal</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("codigo_postal")}
                  defaultValue={""}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("email", { required: true })}
                  defaultValue={""}
                />
                <div className="form-invalid">
                  {errors.email?.type === "required" && "Email es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("telefono")}
                  defaultValue={""}
                />
              </div>
            </div>

            <div className="w-100 d-flex flex-wrap facturas__btn-container">
                {selectedEmpresa && !isFormReadOnly && (
                  <button type="button"
                    className="btn btn-secondary align-self-end ml-2"
                    onClick={() => {
                      reset({
                        nombre_fiscal: "",
                        cif: "",
                        direccion: "",
                        codigo_postal: "",
                        email: "",
                        telefono: "",
                      });
                      setSelectedEmpresa(null);
                      setSelectedValue(null);
                    }}>
                    Reset
                  </button>
                )}
                {!isFormReadOnly && (
                <button type="submit" className="btn btn-primary align-self-end">
                  {selectedEmpresa ? "Actualizar" : "Crear"}
                </button>
                )}
              </div>
          </form>
        </div>
      </div>
    </div>
  );
}
