import Pagination from '@mui/material/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APITramites } from "../../../../services/api/bajas/tramites";
import NuevaBajaDialog from "./Dialogs/NuevaBajaDialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faInfoCircle, faMessage, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { APIDocumentacion } from "../../../../services/api/bajas/documentacion";
import { APIPoblaciones } from "../../../../services/api/bajas/poblaciones";
import ViewerDialog from "./Dialogs/ViewerDialog";
import AprobarBajaDialog from './Dialogs/AprobarBajaDialog';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { APIDocumentosAprobados } from '../../../../services/api/bajas/documentos_aprobados';
import { ShowChart } from '@mui/icons-material';
import { InputLabel, MenuItem, NativeSelect, Select } from '@mui/material';
import './HistoricoBajas.scss'
import InfoModalPrecio from './Dialogs/InfoModalPrecio';
import InfoModal from '../shared/components/InfoModal';

export default function HistoricoBajas({ isEdit, onSubmitCompany }) {
    const limit = 15;
    const [tramites, setTramites] = useState([]);
    const [tramitesFiltered, setTramitesFiltered] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState(false);
    const currPage = parseInt(1);
    const [currentPage, setPage] = useState(currPage);
    const [sortColumn, setSortColumn] = useState(null); // Track which column is being sorted
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sorting order (ascending by default)
    // Estados para los valores seleccionados en los desplegables select
    const [selectedMatricula, setSelectedMatricula] = useState(null);
    const [selectedConcesionario, setSelectedConcesionario] = useState(null);
    //const [selectedCifnif, setSelectedCifnif] = useState(null);
    const [selectedTramite, setSelectedTramite] = useState(null);
    const [selectedEstado, setSelectedEstado] = useState("1");
    const [isNuevaBajaDialog, setIsNuevaBajaDialog] = useState(false);
    const [isAprobarDialog, setIsAprobarDialog] = useState(false);
    const [isInfoModalPrecioDialog, setIsInfoModalPrecioDialog] = useState(false);
    const [infoModalPrecioText, setInfoModalPrecioText] = useState(null);
    const [isInfoModalDialog, setIsInfoModalDialog] = useState(false);
    const [infoModalText, setInfoModalText] = useState(null);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [dataUrl, setDataUrl] = useState(null);
    const userData = JSON.parse(localStorage.getItem("user")) || {};
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        defaultValues: {
        },
    });

    const handleCloseViewer = () => {
        setViewerOpen(false);
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right',
        });
    };

    const showInfoToast = (message) => {
        toast.info(message, {
            position: 'top-right',
        });
    };

    const customWidth = {
        // width: '25%',
        // display: 'inline-block',
        // paddingRight: '10px'
    };

    const handleAprobarConfirm = (tramiteId, confirm) => {
        fetchData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleSortByColumn = (column) => {
        if (column === sortColumn) {
            // Toggle sorting order if the same column is clicked again
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Sort by the clicked column in ascending order by default
            setSortColumn(column);
            setSortOrder('asc');
        }

        // Implement sorting logic based on 'column' and 'sortOrder'
        // You can use the sorted data for rendering your table
        const sortedtramites = tramites; // Create a copy of the array to avoid mutating the original data
        sortedtramites.sort((a, b) => {
            column = column.toString().toLowerCase();
            //excepts
            if (column === 'tipo de factura') {
                column = 'description';
            }
            if (column === 'cif o nif') {
                column = 'cifnif';
            }
            const columnA = a[column];
            const columnB = b[column];

            // Perform null or undefined check before accessing properties
            if (columnA == null || columnB == null) {
                // Handle null or undefined values as needed
                return 0;
            }

            if (typeof columnA === 'string') {
                // Handle string comparison
                if (sortOrder === 'asc') {
                    return columnA.localeCompare(columnB);
                } else {
                    return columnB.localeCompare(columnA);
                }
            } else if (typeof columnA === 'number') {
                // Handle numeric comparison
                if (sortOrder === 'asc') {
                    return columnA - columnB;
                } else {
                    return columnB - columnA;
                }
            } else if (columnA instanceof Date || typeof columnA === 'number') {
                // Handle date or time value comparison
                if (sortOrder === 'asc') {
                    return columnA - columnB;
                } else {
                    return columnB - columnA;
                }
            } else {
                // Handle other data types or custom comparison logic
                // Add custom comparison logic here if needed
                return 0; // Default to no change in order
            }
        });
        setTramitesFiltered(sortedtramites);
    };

    const handleExportExcel = () => {
        const url = getUrlExcel();
        window.location.href = url;
    };

    const getUrlExcel = (event) => {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            //const limitParam = limit ? `limit=${limit}` : '';
            //const currentPageParam = currentPage ? `currentPage=${currentPage}` : '';
            const limitParam = `limit=0`;
            const currentPageParam = '';
            const estadoParam = selectedEstado ? `ftr=estado_id|${selectedEstado}` : '';
            const concesionarioParam = currentUser?.id_concesionario ? `conc=concesionario_id|${currentUser?.id_concesionario}` : '';
            const empresaParam = currentUser?.id_empresa ? `emp=empresa_id|${currentUser?.id_empresa}` : '';
            const queryParams = [tokenParam, limitParam, currentPageParam, estadoParam, concesionarioParam, empresaParam].filter(Boolean).join('&');
            // Cambiar el endpoint al correcto
            return `${process.env.REACT_APP_API_BASE}/excel/getExcelTramites?${queryParams}`;
        }
    };

    function flattenArray(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    const fetchData = async () => {
        const ftr = [];
        if (selectedConcesionario != null && selectedConcesionario != '') {
            ftr.push("concesionario_id|" + selectedConcesionario);
        }
        if (selectedMatricula != null && selectedMatricula != '') {
            ftr.push("matricula|" + selectedMatricula);
        }
        if (selectedEstado != null && selectedEstado != '') {
            ftr.push("estado_id|" + selectedEstado);
        }
        if (userData.id_empresa != null && userData.id_empresa != '') {
            if (userData.rol_id === 2) {
                ftr.push("empresa_id|" + userData.id_empresa);
            }
        }
        try {
            const tramitesData = await APITramites.post({ limit, currentPage, ftr });
            setTramites(tramitesData.tramites);
            setTramitesFiltered(tramitesData.tramites);
            setFilters(tramitesData.filters);
            setPageCount(tramitesData.count);
        } catch (error) {
            showErrorToast('Error al obtener trámites:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedConcesionario, selectedMatricula, selectedEstado, limit, currPage, currentPage]);

    const handleNuevaBajaClick = () => {
        setIsNuevaBajaDialog(true)
    };

    const handleValidarPrecioClick = async (event, tramite) => {
        setSelectedTramite(tramite);
        setInfoModalPrecioText('¿Desea validar el precio indicado por el desguace?');
        setIsInfoModalPrecioDialog(true);
        return;
    };

    const handleAprobarClick = async (event, tramite) => {
        // Evitar que el navegador siga el enlace por defecto
        if (tramite.validar === true && tramite.validado === null && tramite.precio != null) {
            setInfoModalText('Debe esperar a la validación del precio por parte del concesionario para realizar la recogida y aprobación');
            setIsInfoModalDialog(true);
            return;
        }
        if (tramite.validar === true && tramite.validado === null && tramite.precio === null) {
            setSelectedTramite(tramite);
            setInfoModalPrecioText('Debe introducir un precio por el vehículo, el concesionario ha indicado que el vehículo tiene menos de 10 años');
            setIsInfoModalPrecioDialog(true);
            return;
        }
        setSelectedTramite(tramite);
        setIsAprobarDialog(true)
    };

    const handleLinkClick = async (event, data) => {
        // Evitar que el navegador siga el enlace por defecto
        if (data.validar === true && data.validado === null && data.precio != null) {
            setInfoModalText('Debe esperar a la validación del precio por parte del concesionario para realizar la recogida y aprobación');
            setIsInfoModalDialog(true);
            return;
        }
        if (data.validar === true && data.validado === null && data.precio === null) {
            setSelectedTramite(data);
            setInfoModalPrecioText('Debe introducir un precio por el vehículo, el concesionario ha indicado que el vehículo tiene menos de 10 años');
            setIsInfoModalPrecioDialog(true);
            return;
        }
        event.preventDefault();
        try {
            // Función para validar una cadena como fecha en formato YYYY-MM-DD
            const isValidDate = (dateString) => {
                // Expresión regular para el formato "dd/mm/yyyy"
                const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

                // Verificar el formato y si es parseable como una fecha válida
                return regex.test(dateString) && !isNaN(Date.parse(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')));
            };

            // Solicitar al usuario que introduzca la fecha hasta que proporcione una fecha válida
            let userSelectedDate = '';
            while (!isValidDate(userSelectedDate)) {
                userSelectedDate = prompt("Introduce la fecha (dd/mm/yyyy):");

                // Si el usuario cancela, no hacemos nada
                if (userSelectedDate === null) {
                    return;
                }

                // Validar la entrada del usuario y mostrar un mensaje de error si es necesario
                if (!isValidDate(userSelectedDate)) {
                    alert('Fecha no válida. Por favor, introduce una fecha en formato dd/mm/yyyy.');
                }
            }
            data.fecha_recogida = userSelectedDate;
            const result = await APITramites.update(data.id, data);
            if (result) {
                await APIDocumentosAprobados.sendEmailRecogida(data);
                // Llamar a la función post de tu API pasando el objeto formData y el archivo PDF
                data.selectedDoc = "doc_recogida_vehiculo";
                data.fecha_recogida = userSelectedDate;
                const dataPoblacion = await APIPoblaciones.getById(data.concesionario.id_poblacion);
                data.poblacion = dataPoblacion.poblacion;
                const response = await APIDocumentacion.generatePdf(data);
                const { pdfBase64 } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
                // Crear una URL de datos con el contenido base64
                const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
                setDataUrl([pdfDataUrl]);
                setViewerOpen(true);
            } else {
                showInfoToast("Error al procesar la recogida o esta ya ha sido programada");
            }
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const handleLinkReprogramarClick = async (event, data) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        try {
            // Función para validar una cadena como fecha en formato YYYY-MM-DD
            const isValidDate = (dateString) => {
                // Expresión regular para el formato "dd/mm/yyyy"
                const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

                // Verificar el formato y si es parseable como una fecha válida
                return regex.test(dateString) && !isNaN(Date.parse(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')));
            };

            // Solicitar al usuario que introduzca la fecha hasta que proporcione una fecha válida
            const currentDate = new Date(); // Obtener la fecha actual
            let userSelectedDate = '';
            while (true) {
                userSelectedDate = prompt("Introduce la fecha (dd/mm/yyyy):");

                if (userSelectedDate === null) {
                    return; // Si el usuario cancela, salimos de la función
                }

                // Función para validar el formato de la fecha
                const isValidDate = (dateString) => {
                    const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
                    return regex.test(dateString) && !isNaN(Date.parse(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')));
                };

                if (!isValidDate(userSelectedDate)) {
                    alert('Fecha no válida. Por favor, introduce una fecha en formato dd/mm/yyyy.');
                    continue; // Reiniciamos el bucle para solicitar una fecha válida
                }

                const selectedDate = new Date(userSelectedDate.split('/').reverse().join('/'));

                if (selectedDate < currentDate) {
                    alert('La fecha no puede ser anterior a la fecha actual.');
                    continue; // Reiniciamos el bucle para solicitar una fecha válida
                }

                if (data.fecha_recogida && selectedDate < new Date(data.fecha_recogida)) {
                    alert('La nueva fecha de recogida no puede ser anterior a la fecha anterior.');
                    continue; // Reiniciamos el bucle para solicitar una fecha válida
                }

                // Si llegamos aquí, la fecha es válida, salimos del bucle
                break;
            }
            data.fecha_recogida = userSelectedDate;
            const result = await APITramites.update(data.id, data);
            if (result) {
                await APIDocumentosAprobados.sendEmailReproRecogida(data);
            } else {
                showInfoToast("Error al procesar la recogida o esta ya ha sido programada");
            }
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const handleLinkClickDocs = async (event, data) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        try {
            const response = await APIDocumentacion.getAllDocs({ id: data.documentos_baja_id });
            const { pdfBase64Array } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
            // Crear una URL de datos con el contenido base64
            const pdfDataUrls = pdfBase64Array.map((pdfBase64) => `data:application/pdf;base64,${pdfBase64}`); setDataUrl(pdfDataUrls);
            setViewerOpen(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const handleLinkBaseClick = async (event, urlDoc) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        try {
            // Llamar a la función post de tu API pasando el objeto formData y el archivo PDF
            const response = await APIDocumentacion.getDoc({ urlDoc: urlDoc });
            const { pdfBase64 } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
            // Crear una URL de datos con el contenido base64
            const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
            setDataUrl([pdfDataUrl]);
            setViewerOpen(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const ColumnHeader = ({ title, onFilter, onSort, sortOrder }) => {
        const inputRef = useRef(null);
        const handleSortChange = () => {
            onSort(title);
        };

        return (
            <th>
                <div>
                    <span>{title}</span>
                    <span style={{ cursor: 'pointer' }} onClick={handleSortChange}>
                        &nbsp;{sortOrder === 'asc' ? '▲' : '▼'}
                    </span>
                </div>
            </th>
        );
    };

    const getRows = (listtramites) => {

        return listtramites.map((tramite, index) => {
            const concesionarioText = tramite.concesionario && tramite.concesionario.nombre
                ? tramite.concesionario.nombre.length > 13
                    ? tramite.concesionario.nombre.substring(0, 13) + "..."
                    : tramite.concesionario.nombre
                : 'Desconocido';
            return <tr key={`tramite-${index}`}>
                <td style={{ width: "2%", fontSize: "100%" }}>
                    {tramite.id}
                    {userData?.rol_id === 3 && tramite.obs ? (
                        <>
                            <Tooltip title={tramite.obs ? `Observaciones: ` + tramite.obs : 'Sin observaciones'} arrow
                                placement="left-start" sx={{ fontSize: "30" }}
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            color: "#215732",
                                            backgroundColor: "#ffff",
                                        },
                                    },
                                }}>
                                <span style={{ marginLeft: "0.3rem" }}>
                                    <FontAwesomeIcon className='icon-info-orange' color='#215833' icon={faMessage} />
                                </span>
                            </Tooltip>
                        </>
                    ) : null}
                    {userData?.rol_id === 3 && tramite.precio ? (
                        <>
                            <Tooltip title={tramite.validado ? `Precio validado: ` + tramite.precio : 'Precio ofertado:' + tramite.precio} arrow
                                placement="left-start" sx={{ fontSize: "30" }}
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            color: "#215732",
                                            backgroundColor: "#ffff",
                                        },
                                    },
                                }}>
                                <span style={{ marginLeft: "0.3rem" }}>
                                    <FontAwesomeIcon className='icon-info-orange' color={tramite.validado ? 'orange' : '#215732'} icon={faCoins} />
                                </span>
                            </Tooltip>
                        </>
                    ) : null}
                </td>
                <td style={{ width: "3%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{tramite.fecha_registro ? format(new Date(tramite.fecha_registro), 'dd/MM/yyyy') : 'Fecha Desconocida'}</td>
                <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "120px" }}>{tramite.tipo_baja ? tramite.tipo_baja.descripcion : 'Tipo Desconocido'}</td>
                <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>{tramite.matricula ? tramite.matricula : 'Matrícula Desconocida'}</td>
                <td title={tramite?.bastidor ? tramite?.bastidor : 'Bastidor Desconocido'} style={{ width: "4%", fontSize: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px" }}>
                    {tramite?.bastidor ? tramite?.bastidor : 'Bastidor Desconocido'}
                </td>
                <td style={{ width: "6%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>{tramite.marca ? tramite.marca.nombre : 'Marca Desconocida'}</td>
                <td style={{ width: "9%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{tramite.modelo ? tramite.modelo.nombre : 'Modelo Desconocido'}</td>
                <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "90px" }}>{tramite.estado ? tramite.estado.descripcion : 'Estado Desconocido'}</td>
                {userData?.rol_id === 3 ? (
                    <>
                        <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "120px" }} title={tramite.empresa ? tramite.empresa.nombre_fiscal : 'Desconocida'}>{tramite.empresa ? tramite.empresa.nombre_fiscal : 'Desconocida'}</td>
                    </>
                ) : null}
                <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "140px" }} title={tramite.concesionario ? tramite.concesionario.nombre : 'Desconocido'}>{concesionarioText}</td>
                <Tooltip title={tramite.responsable ? "Teléfono: " + tramite.responsable.telefono + " E-mail:" + tramite.responsable.email : "No hay datos de contacto disponibles"} arrow
                    placement="left-start" sx={{ fontSize: "30" }}
                    slotProps={{
                        tooltip: {
                            sx: {
                                color: "#514E6A",
                                backgroundColor: "#ffff",
                            },
                        },
                    }}>
                    <td style={{ width: "5%", fontSize: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "140px" }}>{tramite.responsable ? tramite.responsable.nombre + ' ' + tramite.responsable.apellido1 : 'Desconocido'}</td>
                </Tooltip>
                {(userData?.rol_id === 3) && selectedEstado === "1" ? (
                    <>
                        <td style={{ width: "3%", fontSize: "100%" }}>
                            <a style={{ marginLeft: "1rem" }} href="#" onClick={(event) => handleLinkClickDocs(event, tramite)}>
                                <FontAwesomeIcon icon={faDownload} />
                            </a>
                        </td>
                        {tramite.fecha_recogida ? (
                            <td style={{ width: "1%", fontSize: "100%" }}>
                                <Tooltip title={tramite.fecha_recogida ? `Recogida programada para el ` + format(new Date(tramite.fecha_recogida), 'dd/MM/yyyy') : ''} arrow
                                    placement="left-start" sx={{ fontSize: "30" }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                color: "#514E6A",
                                                backgroundColor: "#ffff",
                                            },
                                        },
                                    }}>
                                    <span style={{ marginLeft: "1rem" }}>
                                        <FontAwesomeIcon className='icon-info-orange' color='orange' icon={faInfoCircle} />
                                    </span>
                                </Tooltip>
                            </td>
                        ) : (
                            <td style={{ width: "1%", fontSize: "100%" }}>
                                <a style={{ marginLeft: "1rem" }} href="#" onClick={(event) => handleLinkClick(event, tramite)}>
                                    <FontAwesomeIcon icon={faFile} />
                                </a>
                            </td>
                        )}
                        <td style={{ width: "1%", fontSize: "100%" }}>
                            <button
                                className='table-responsive__btn'
                                onClick={(event) => handleAprobarClick(event, tramite)}
                                onMouseOver={(event) => {
                                    event.currentTarget.style.backgroundColor = '#1c4a2b';
                                    event.currentTarget.style.borderColor = '#1a4628';
                                }}
                                onFocus={(event) => {
                                    event.currentTarget.style.boxShadow = '0 0 0 0.25rem rgba(66, 112, 81, 0.5)';
                                }}
                                onMouseDown={(event) => {
                                    event.currentTarget.style.backgroundColor = '#1a4628';
                                    event.currentTarget.style.borderColor = '#194126';
                                    event.currentTarget.style.boxShadow = 'inset 0 3px 5px rgba(0, 0, 0, 0.125)';
                                }}
                                onMouseUp={(event) => {
                                    event.currentTarget.style.backgroundColor = '#1c4a2b';
                                    event.currentTarget.style.borderColor = '#1a4628';
                                    event.currentTarget.style.boxShadow = 'inset 0 3px 5px rgba(0, 0, 0, 0.125)';
                                }}
                                onBlur={(event) => {
                                    event.currentTarget.style.boxShadow = 'none';
                                }}
                            >
                                Aprobar</button>
                        </td>
                    </>
                ) : null
                }
                {
                    (userData?.rol_id === 2 || userData?.rol_id === 3) && selectedEstado === "2" ? (
                        <>
                            <td style={{ width: "7%", fontSize: "120%" }}>
                                <a style={{ marginLeft: "5px" }} href="#" onClick={(event) => handleLinkBaseClick(event, tramite.documentos_aprobado.certificado)}>
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </a>
                                <a style={{ marginLeft: "5px" }} href="#" onClick={(event) => handleLinkBaseClick(event, tramite.documentos_aprobado.bajadgt)}>
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </a>
                            </td>
                        </>
                    ) : null
                }
                {
                    (userData?.rol_id === 2 && selectedEstado === "1") ? (
                        <>
                            <td style={{ width: "5%", fontSize: "100%" }}>
                                {tramite.fecha_recogida ? (
                                    // Mostrar este span si la fecha_recogida está informada
                                    <Tooltip title={tramite.fecha_recogida ? `Recogida programada para el ` + format(new Date(tramite.fecha_recogida), 'dd/MM/yyyy') : ''} arrow
                                        placement="left-start" sx={{ fontSize: "30" }}
                                        slotProps={{
                                            tooltip: {
                                                sx: {
                                                    color: "#514E6A",
                                                    backgroundColor: "#ffff",
                                                },
                                            },
                                        }}>
                                        <a style={{ marginLeft: "1rem" }} href="#" onClick={(event) => handleLinkReprogramarClick(event, tramite)}>
                                            <FontAwesomeIcon className='icon-info-orange' color='orange' icon={faInfoCircle} />
                                        </a>
                                    </Tooltip>
                                ) : (
                                    // Mostrar este span si la fecha_recogida no está informada
                                    <Tooltip title="Pendiente de confirmación por parte del desguace." arrow
                                        placement="left-start" sx={{ fontSize: "30" }}
                                        slotProps={{
                                            tooltip: {
                                                sx: {
                                                    color: "#514E6A",
                                                    backgroundColor: "#ffff",
                                                },
                                            },
                                        }}>
                                        <span style={{ marginLeft: "1rem" }}>
                                            <FontAwesomeIcon color='black' icon={faInfoCircle} />
                                        </span>
                                    </Tooltip>
                                )}
                                {tramite.validar === true && tramite.precio != null && tramite.validado === null ? (
                                    // Mostrar este span si la fecha_recogida está informada
                                    <Tooltip title={tramite.precio ? `¿Validar precio? ` + tramite.precio : ''} arrow
                                        placement="left-start" sx={{ fontSize: "30" }}
                                        slotProps={{
                                            tooltip: {
                                                sx: {
                                                    color: "#514E6A",
                                                    backgroundColor: "#ffff",
                                                },
                                            },
                                        }}>
                                        <a style={{ marginLeft: "1rem" }} href="#" onClick={(event) => handleValidarPrecioClick(event, tramite)}>
                                            <FontAwesomeIcon className='icon-info-orange' color='gray' icon={faCoins} />
                                        </a>
                                    </Tooltip>
                                ) : (
                                    null
                                )}
                            </td>
                        </>
                    ) : null
                }

            </tr >
        })
    }

    return (
        <div className="pt-3">
            {isNuevaBajaDialog && <NuevaBajaDialog state={isNuevaBajaDialog} onConfirmAction={handleAprobarConfirm} onClose={() => { setIsNuevaBajaDialog(false) }} />}
            {isAprobarDialog && <AprobarBajaDialog tramite={selectedTramite} state={isAprobarDialog} onConfirmAction={handleAprobarConfirm} onClose={() => { setIsAprobarDialog(false) }} />}
            <ToastContainer />
            {isInfoModalPrecioDialog && <InfoModalPrecio tramite={selectedTramite} title="Validar precio de vehículo" content={infoModalPrecioText} state={isInfoModalPrecioDialog} onClose={() => { setIsInfoModalPrecioDialog(false) }} />}
            {isInfoModalDialog && <InfoModal tramite={selectedTramite} title="Validar precio de vehículo" content={infoModalText} state={isInfoModalDialog} onClose={() => { setIsInfoModalDialog(false) }} />}
            <h1>Histórico de Bajas</h1>
            <div id="historicFilters" className="historic-filters">
                <div className="each-container-select" style={customWidth}>
                    <InputLabel id="estado-label" className="pb-1">Estado</InputLabel>
                    <Select
                        labelid='estado-label'
                        id='estado'
                        value={selectedEstado}
                        onChange={(e) => {
                            setSelectedEstado(e.target.value);
                        }}
                    >
                        <MenuItem value="1">
                            En curso
                        </MenuItem>
                        <MenuItem value="2">
                            Finalizadas
                        </MenuItem>
                    </Select>
                </div>

                <div className="each-container-select" style={customWidth}>
                    <InputLabel id="matricula-label" className="pb-1">Matricula</InputLabel>
                    <Autocomplete
                        labelid="matricula-label"
                        id='matricula'
                        value={selectedMatricula}
                        onChange={(event, newValue) => {
                            setSelectedMatricula(newValue);
                        }}
                        options={flattenArray(filters.distinctMatriculas)}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Filtrar por matrícula"
                                variant="outlined"
                                fullWidth
                                size="small" // Ajusta según tus preferencias
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {params.InputProps.startAdornment}
                                            {params.inputProps.placeholder && (
                                                <span style={{ opacity: 0.5, marginLeft: 8 }}>
                                                    {params.inputProps.placeholder}
                                                </span>
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>

                <div style={customWidth} className="each-container-select">
                    <InputLabel id="concesionario-label" className="pb-1">Concesionario</InputLabel>
                    <Select
                        labelid='concesionario-label'
                        id='concesionario'
                        value={selectedConcesionario || ''}
                        onChange={(e) => {
                            setSelectedConcesionario(e.target.value);
                        }}
                    >
                        {flattenArray(filters.distinctConcesionarios).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                    {!selectedConcesionario && <span className='each-container-select__placeholder'>Filtrar por Concensionario</span>}
                </div>

                <div style={customWidth} className="flex flex-col flex-1 btns-input-container">
                    {userData?.rol_id === 2 ? (
                        <>
                            <button className="btn btn-primary" onClick={() => handleNuevaBajaClick()}>
                                Nueva Baja
                            </button>
                            <button className="btn btn-primary" onClick={() => handleExportExcel()}>
                                Excel
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="w-100 facturas-list-item">
                <div className="table-responsive">
                    <table className="table mb-4">
                        <thead>
                            <tr>
                                <ColumnHeader
                                    title="Tramite"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'ID Tramite' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="F.Registro"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'F.Registro' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Tipo"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Tipo' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Matricula"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Matricula' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Bastidor"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Bastidor' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Marca"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Marca' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Modelo"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Modelo' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Estado"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Estado' ? sortOrder : null}
                                />
                                {userData?.rol_id === 3 ? (
                                    <>
                                        <ColumnHeader
                                            title="Empresa"
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Empresa' ? sortOrder : null}
                                        />
                                    </>
                                ) : null}
                                <ColumnHeader
                                    title="Concesionario"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Concesionario' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Responsable"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Responsable' ? sortOrder : null}
                                />
                                {(userData?.rol_id === 3) && selectedEstado === "1" ? (
                                    <>
                                        <ColumnHeader
                                            title="Doc. Baja"
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Doc. Baja' ? sortOrder : null}
                                        />
                                        <ColumnHeader
                                            title="Recogida"
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Recogida' ? sortOrder : null}
                                        />
                                        <ColumnHeader
                                            title="Aprobar"
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Aprobar' ? sortOrder : null}
                                        />
                                    </>
                                ) : null}
                                {(userData?.rol_id === 2 || userData?.rol_id === 3) && selectedEstado === "2" ? (
                                    <>
                                        <ColumnHeader
                                            title="Cert. / Baja DGT"
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Documentos' ? sortOrder : null}
                                        />
                                    </>
                                ) : null}
                                {(userData?.rol_id === 2 && selectedEstado === "1") ? (
                                    <>
                                        <ColumnHeader
                                            title="Info."
                                            onSort={handleSortByColumn}
                                            sortOrder={sortColumn === 'Info.' ? sortOrder : null}
                                        />
                                    </>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {tramites !== null && tramites.length > 0 ? (
                                getRows(tramites)
                            ) : (
                                <tr>
                                    <td colSpan="13">
                                        No hay tramites disponibles
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pagination
                        className="mt-3"
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            </div>
            <ViewerDialog
                dataUrl={dataUrl}
                open={viewerOpen}
                onClose={handleCloseViewer}
            />
        </div>
    );
}
