import { faCircleInfo, faCircleXmark, faClock, faComments, faFileText, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@mui/material/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useScreenWidth from '../../../../hooks/useScreenWidth';
import { APICompras } from "../../../../services/api_wallapop/compras/ofertas/";
import '../CompraFIATC/CompraFIATC.scss';
import ViewerDialog from "../HistoricoBajas/Dialogs/ViewerDialog";
import ColumnHeader from '../shared/components/CompraVehiculos/ColumnHeader';
import SkeletonEachResult from '../shared/components/CompraVehiculos/SkeletonEachResult';
import TooltipIcon from '../shared/components/CompraVehiculos/TooltipIcon';
import InfoVehiculoWallapopDialog from "./Dialogs/InfoVehiculoWallapopDialog";
import InfoModal from '../shared/components/InfoModal';

const CompraWallapop = () => {
    const limit = 15;
    const [ofertas, setOfertas] = useState([]);
    const [ofertasFiltered, setOfertasFiltered] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState(false);
    const currPage = parseInt(1);
    const [currentPage, setPage] = useState(currPage);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [dataUrl, setDataUrl] = useState(null);
    const userData = JSON.parse(localStorage.getItem("user")) || {};
    const [isInfoVehiculoDialog, setIsInfoVehiculoDialog] = useState(false);
    const [selectedOferta, setSelectedOferta] = useState(null);
    const [activeTab, setActiveTab] = useState("pendientes"); // Track the active tab
    const [isLoading, setIsLoading] = useState(false)
    const [isInfoModalDialog, setIsInfoModalDialog] = useState(false);
    const [infoModalText, setInfoModalText] = useState(null);

    const screenWidth = useScreenWidth()

    const handleCloseViewer = () => {
        setViewerOpen(false);
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'bottom-left',
        });
    };

    const showInfoToast = (message) => {
        toast.info(message, {
            position: 'bottom-left',
        });
    };

    const handleInfoVehiculoClick = (e, oferta) => {
        setIsInfoVehiculoDialog(true)
        setSelectedOferta(oferta);
    };

    const handleOferta = async (e, oferta) => {
        const data = { "id": oferta.id };
        try {
            const resultado = await APICompras.aceptarOferta(data);
            showInfoToast(resultado.Message);
            fetchData(activeTab === "pendientes" ? 0 : 1);
        } catch (error) {
            showErrorToast('Error al realizar la oferta' + error);
        }
    };

    const handleRechazarOferta = async (e, oferta) => {
        const data = { "id": oferta.id };
        try {
            const resultado = await APICompras.rechazarOferta(data);
            showInfoToast(resultado.Message);
            fetchData(activeTab === "pendientes" ? 0 : 1);
        } catch (error) {
            showErrorToast('Error al rechazar la oferta' + error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchData(activeTab === "pendientes" ? 0 : 1, newPage);
    }

    const fetchData = async (estado, currentPage) => {
        try {
            setIsLoading(true);
            setOfertas([]);
            const ofertasData = await APICompras.obtenerDatos({
                "state": estado,
                "pagina": currentPage || 1,
                "resultadosPorPagina": limit
            });
            setOfertas(ofertasData.ofertas);
            setOfertasFiltered(ofertasData.ofertas);
            setPage(ofertas.paginaActual || currentPage);
            setPageCount(ofertasData.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            showErrorToast('Error al obtener ofertas:', error);
        }
    };

    useEffect(() => {
        fetchData(activeTab === "pendientes" ? 0 : 1, currentPage); // Fetch data based on active tab
    }, [activeTab]); // Update data on tab change

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPage(1); // Reset page number on tab change
    };

    const handleConversaClick = async (event, id_user) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        let urlChat = "https://es.wallapop.com/app/chat?itemId=" + id_user
        try {
            window.open(urlChat, '_blank');
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al abrir la url:', error);
        }
    };

    const handleLinkBaseClick = async (event, description) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        try {
            setInfoModalText(description);
            setIsInfoModalDialog(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            showErrorToast('Error al generar el PDF:', error);
        }
    };

    const formatDate = (dateString) => {
        if (dateString) {
            return format(new Date(dateString), 'dd-MM-yyyy'); // Customize format as needed
        }
        return '';
    };

    const getRows = (listOfertas) => {

        return listOfertas.map((oferta, index) => {
            return <tr key={`oferta-${index}`} style={{ borderLeft: oferta?.resolucion === 'OK' ? 'green solid 3px' : oferta?.resolucion === 'NOK' ? 'red solid 3px' : '' }}>
                <td style={{ width: "2%", fontSize: "0.9rem" }}>
                    {oferta.id}
                </td>
                <td title={oferta.title} style={{ width: "12%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "120px" }}>{oferta.title}</td>
                <td style={{ width: "3%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.creation_date ? format(new Date(oferta.creation_date), 'dd/MM/yyyy') : 'Fecha Desconocida'}</td>
                <td
                    title={`${oferta.brand ? oferta.brand : "No informado"} ${oferta.model ? oferta.model : ""} (${oferta.year ? oferta.year : "No informado"})`}
                    style={{ width: "10%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "400px" }}
                >
                    {oferta.brand && oferta.model && oferta.year
                        ? `${oferta.brand} ${oferta.model} (${oferta.year})`
                        : "No informado, mirar título de la oferta"}
                </td>                <td style={{ width: "8%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.km}</td>
                <td title={oferta.location} style={{ width: "8%", fontSize: "0.9rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "110px" }}>{oferta.location}</td>
                <td style={{ width: "4%", fontSize: "0.9rem" }}>
                    <span>{oferta.price !== null ? oferta.price + "€" : "N/A"}</span>
                </td>
                <td style={{ width: "3%", fontSize: "0.9rem" }}>
                    {getBtns(oferta)}
                </td>
            </tr >
        })
    }

    const getCards = (listOfertas) => {
        return listOfertas.map((oferta, index) => {
            return <div key={`oferta-${index}`} className='ofertas__card' style={{ borderLeft: oferta?.resolucion === 'OK' ? 'green solid 3px' : oferta?.resolucion === 'NOK' ? 'red solid 3px' : '' }}>
                <div className='ofertas__card--titles'><span>Id</span><span>Título</span><span>Vehículo</span></div>
                <div className='ofertas__card--text'>
                    <span>{oferta.id}</span>
                    <span>{oferta.title}</span>
                    <span>{`${oferta.brand ? oferta.brand : "No informado"} ${oferta.model ? oferta.model : ""} (${oferta.year ? oferta.year : "No informado"})`}</span>
                </div>
                <div className='ofertas__card--titles'><span>Km</span><span>Localización</span><span>Precio</span></div>
                <div className='ofertas__card--text'>
                    <span>{oferta.km}</span>
                    <span>{oferta.location}</span>
                    <span>{oferta.price !== null ? oferta.price + "€" : "No recibido"}</span>
                </div>
                <div className='ofertas__card--titles second'><span>Fecha Creación</span></div>
                <div className='ofertas__card--text second'>
                    <span>{oferta.creation_date ? format(new Date(oferta.creation_date), 'dd/MM/yyyy') : 'Fecha Desconocida'}</span>
                </div>
                {getBtns(oferta)}
            </div >
        })
    }

    const getBtns = (oferta) => {
        return <div className='ofertas__card--icons'>
            <span className='ofertas__card--icons--clock'>
                <TooltipIcon
                    title={oferta.fecha_resolucion ? `La oferta finaliza el: ` + formatDate(oferta.fecha_resolucion) : 'No existe fecha de fin especifica'}
                    icon={faClock} />
            </span>
            <span className='ofertas__card--icons--pdf' onClick={(event) => handleLinkBaseClick(event, oferta.description)}>
                <TooltipIcon title='Ver Descripción' icon={faFileText} />
            </span>
            <button
                className='ofertas__card--icons--offer'
                onClick={(event) => handleConversaClick(event, oferta.id_user)}
                disabled={oferta?.rejected === 1}
            >
                <TooltipIcon title='Escribir a vendedor' icon={faComments} />
            </button>
            <button
                className='ofertas__card--icons--info'
                onClick={(event) => handleInfoVehiculoClick(event, oferta)}
            >
                <TooltipIcon title='+ Info' icon={faCircleInfo} />
            </button>
            <button
                className='ofertas__card--icons--offer'
                onClick={(event) => handleOferta(event, oferta)}
                disabled={oferta?.state === 1 && oferta?.rejected != 1}
            >
                <TooltipIcon title='Aceptar' icon={faCircleCheck} />
            </button>
            <button
                className='ofertas__card--icons--cancel'
                onClick={(event) => handleRechazarOferta(event, oferta)}
                disabled={oferta?.rejected === 1}
            >
                <TooltipIcon title='Rechazar' icon={faCircleXmark} />
            </button>
        </div>
    }

    return (
        <div className="content pt-3 ofertas">
            {isInfoVehiculoDialog && <InfoVehiculoWallapopDialog vehicleInfo={selectedOferta} state={isInfoVehiculoDialog} onClose={() => { setIsInfoVehiculoDialog(false) }} />}
            {isInfoModalDialog && <InfoModal title="Descripción de la oferta" content={infoModalText} state={isInfoModalDialog} onClose={() => { setIsInfoModalDialog(false) }} />}
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable />
            <h1>Compra de Vehículos Wallapop (DEMO VERSION)</h1>
            <div className="tabs">
                <button onClick={() => handleTabChange("pendientes")} className={`tabs__tab ${activeTab === "pendientes" ? "tabs__tab--selected" : ""}`}>
                    Pendientes
                </button>
                <button onClick={() => handleTabChange("finalizadas")} className={`tabs__tab ${activeTab === "finalizadas" ? "tabs__tab--selected" : ""}`}>
                    Finalizadas
                </button>
            </div>
            <div className="w-100 ofertas-list-item">
                <div className="table-responsive">
                    {!isLoading
                        ? screenWidth < 992
                            ? <div className='ofertas__container'>
                                {ofertas !== null && ofertas.length > 0 ? (
                                    getCards(ofertas)
                                ) : (
                                    <div>
                                        <span>
                                            No hay ofertas disponibles
                                        </span>
                                    </div>
                                )}
                            </div>
                            : <table className="table mb-4">
                                <thead>
                                    <tr>
                                        <ColumnHeader
                                            title="Id"
                                        />
                                        <ColumnHeader
                                            title="Título"
                                        />
                                        <ColumnHeader
                                            title="Fecha Crea."
                                        />
                                        <ColumnHeader
                                            title="Vehículo"
                                        />
                                        <ColumnHeader
                                            title="Km"
                                        />
                                        <ColumnHeader
                                            title="Localización"
                                        />
                                        <ColumnHeader
                                            title="Precio"
                                        />
                                        <ColumnHeader
                                            title=""
                                        />
                                    </tr>
                                </thead>
                                <tbody>
                                    {ofertas !== null && ofertas.length > 0 ? (
                                        getRows(ofertas)
                                    ) : (
                                        <tr>
                                            <td colSpan="13">
                                                No hay ofertas disponibles
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        : <><SkeletonEachResult /><SkeletonEachResult /><SkeletonEachResult /></>}

                    <Pagination
                        className="mt-3"
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            </div>
            <ViewerDialog
                dataUrl={dataUrl}
                open={viewerOpen}
                onClose={handleCloseViewer}
            />
        </div>
    );
}

export default CompraWallapop
