import React from "react";
import HistoricoBajas from "../HistoricoBajas";
import CompraFIATC from "../CompraFIATC";


export default function Inicio({ }) {
    const user = JSON.parse(localStorage.getItem('user'));
    // Conditional rendering based on rol_id
    return (
        <div className="content">
            {user.rol_id === 4 ? <CompraFIATC /> : <HistoricoBajas />}
        </div>
    );
}