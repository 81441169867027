
import { Route, Routes, Navigate } from "react-router-dom";

import Inicio from './components/Inicio';
import EmpresasView from './components/Empresas';
import ConcesionariosView from './components/Concesionarios';
import UsuariosView from './components/Usuarios';
import DesguacesView from './components/Desguaces';
import FacturasView from './components/Facturas';
import Config from "./components/shared/components/Config";
import Navbar from "./components/shared/components/Navbar";
import MiCuentaView from './components/Micuenta';
import CompraFIATCView from './components/CompraFIATC';
import CompraMutuaView from './components/CompraMutua';
import CompraWallapopView from './components/CompraWallapop';

const Main = ({onLogout, usuario}) => {

      return (
        <div className="dashboard-container">
            <Navbar onLogout={onLogout} usuario={usuario} />

            <Config />

         
            <Routes>
                <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                <Route path="/" exact element={<Inicio />}></Route>
                <Route path="/datos-empresa" element={<EmpresasView />}></Route>
                <Route path="/facturas" element={<FacturasView />}></Route>
                <Route path="/datos-concesionario" element={<ConcesionariosView />}></Route>
                <Route path="/datos-usuario" element={<UsuariosView />}></Route>
                <Route path="/datos-desguace" element={<DesguacesView />}></Route>
                <Route path="/compra-fiatc" element={<CompraFIATCView />}></Route>
                <Route path="/compra-mutua" element={<CompraMutuaView />}></Route>
                <Route path="/compra-wallapop" element={<CompraWallapopView />}></Route>
                <Route path="/mi-cuenta" element={<MiCuentaView />}></Route>
            </Routes>

        </div> 
    );
}

/**

 */

export default Main