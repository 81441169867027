import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIConcesionarios } from "../../../../../services/api/bajas/concesionarios";
import { APIDocumentacion } from "../../../../../services/api/bajas/documentacion";
import { APIMarcas } from "../../../../../services/api/bajas/marcas";
import { APIModelos } from "../../../../../services/api/bajas/modelos";
import { APITramites } from "../../../../../services/api/bajas/tramites";
import { APIUsuarios } from "../../../../../services/api/bajas/usuarios";
import ViewerDialog from "..//Dialogs/ViewerDialog";

export default function NuevaBajaDialog({
    onConfirmAction,
    onClose,
    state,
}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({});

    const [personas, setPersonas] = useState([]);
    const [concesionarios, setConcesionarios] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [selectedValuePersonas, setSelectedValuePersonas] = useState(null);
    const [selectedValueMarcas, setSelectedValueMarcas] = useState(null);
    const [selectedValueModelos, setSelectedValueModelos] = useState(null);
    const [selectedTitular, setSelectedTitular] = useState(null);
    const [selectedDocv, setSelectedDocv] = useState(null);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [dataUrl, setDataUrl] = useState(null);
    const [selectedConcesionario, setSelectedConcesionario] = useState(null);
    const [validar, setValidar] = useState(false);

    const handleChange = () => {
        setValidar(!validar);
        setValue("validar", !validar);
    };

    useEffect(() => {
        const idConcesionario = JSON.parse(localStorage.getItem('user'))?.id_concesionario;
        if (idConcesionario != null) {
            fetchDataConcesionarios(idConcesionario);
        } else {
            fetchDataConcesionariosByEmpresa(JSON.parse(localStorage.getItem('user'))?.id_empresa);
        }
    }, []);

    const handleConfirm = async (confirm) => {
        try {
            onClose();
            onConfirmAction(confirm);
        } catch (error) {
            showErrorToast('Error al crear la baja. Por favor, inténtalo de nuevo más tarde.');
        }
    };

    const handleCloseViewer = () => {
        setViewerOpen(false);
    };

    const handleClose = () => {
        onClose();
    };

    const handleTitularChange = (e) => {
        const selectedValue = e.target.value;
        setValue("tipo_titular_id", null);
        setSelectedTitular(selectedValue);
        setValue("tipo_titular_id", selectedValue);
        // Restablece el valor de los campos de archivos al cambiar la opción de empresa
        setValue("doc_baja_definitiva", null);
        setValue("doc_autorizacion", null);
        setValue("doc_dni_titular", null);
        setValue("doc_baja_definitiva_empresa", null);
        setValue("doc_repre_juridica", null);
        setValue("doc_autorizacion_empresa", null);
        setValue("doc_dni_administrador", null);
        setValue("doc_cif_empresa", null);
        setValue("doc_vehiculo_extravio", null);
    };

    const handleDocVehiculoChange = (e) => {
        setValue("doc_vehiculo_extravio", null);
        const selectedValue = e.target.value;
        //setValue("doc_vehiculo", null);
        setSelectedDocv(selectedValue);
        if (selectedValue === "0") {
            setValue("doc_vehiculo", true);
        } else if (selectedValue === "1") {
            setValue("doc_vehiculo", false);
        }
    };

    const handleFileChange = (event, id) => {
        setValue(id + "_file", null);
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
            const archivoSeleccionado = fileInput.files[0];
            const reader = new FileReader();
            // Obtener el tipo MIME y el nombre del archivo
            const mimeType = archivoSeleccionado.type;
            const fileName = archivoSeleccionado.name;
            reader.onload = () => {
                const base64String = reader.result;
                setValue(id + "_file", { base64String: base64String, mimetype: mimeType, originalname: fileName });
            };
            reader.readAsDataURL(archivoSeleccionado);
        } else {
            setValue(id + "_file", null);
        }
    };

    const submit = async (data, e) => {
        e.preventDefault();
        const submitButton = e.target.querySelector('button[type="submit"]');
        submitButton.disabled = true; // Desactivar el botón de submit

        data.empresa_id = JSON.parse(localStorage.getItem('user'))?.id_empresa;
        const documentoForm = new FormData();
        try {
            const res = await APITramites.create(data);
            showToast('Baja creada con éxito');
            handleConfirm(res);
        } catch (err) {
            showErrorToast('Error al crear la baja. Por favor, inténtalo de nuevo más tarde.');
        } finally {
            submitButton.disabled = false; // Volver a habilitar el botón de submit
        }
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const Titulares = [
        { id: 1, nombre: "Particular" },
        { id: 2, nombre: "Empresa" },
    ];

    const DocVehiculo = [
        { id: 0, nombre: "Sí" },
        { id: 1, nombre: "No" },
    ];

    const handleLinkClick = async (event, selectedDoc) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        const data = getValues();
        try {
            data.selectedDoc = selectedDoc;
            data.marcaDesc = selectedValueMarcas ? selectedValueMarcas.nombre : "";
            data.modeloDesc = selectedValueModelos ? selectedValueModelos.nombre : "";
            data.marcaModeloDesc = (selectedValueMarcas ? selectedValueMarcas.nombre : "") + " - " + (selectedValueModelos ? selectedValueModelos.nombre : "");
            // Llamar a la función post de tu API pasando el objeto formData y el archivo PDF
            const response = await APIDocumentacion.generatePdf(data);
            const { pdfBase64 } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
            // Crear una URL de datos con el contenido base64
            const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
            setDataUrl([pdfDataUrl]);
            setViewerOpen(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            console.error('Error al generar el PDF:', error);
        }
    };

    const handleLinkBaseClick = async (event, selectedDoc) => {
        // Evitar que el navegador siga el enlace por defecto
        event.preventDefault();
        // Obtener todos los valores del formulario
        const data = getValues();
        try {
            data.selectedDoc = selectedDoc;
            // Llamar a la función post de tu API pasando el objeto formData y el archivo PDF
            const response = await APIDocumentacion.getPdf(data);
            const { pdfBase64 } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
            // Crear una URL de datos con el contenido base64
            const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
            setDataUrl([pdfDataUrl]);
            setViewerOpen(true);
        } catch (error) {
            // Manejar errores, por ejemplo, mostrar un mensaje de error
            console.error('Error al generar el PDF:', error);
        }
    };

    const fetchDataUsuarios = async (idUsuario) => {
        try {
            const dataUsuarios = await APIUsuarios.get(idUsuario, '-1');
            setPersonas(dataUsuarios.usuarios);
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
        }
    };

    const fetchDataUsuariosByEmpresa = async (empresaId) => {
        try {
            const dataUsuarios = await APIUsuarios.get('-1',empresaId);
            setPersonas(dataUsuarios.usuarios);
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
        }
    };

    const fetchDataConcesionarios = async (inputValue) => {
        try {
            const dataConcesionarios = await APIConcesionarios.getById(inputValue);
            setConcesionarios([dataConcesionarios.concesionario]);
        } catch (error) {
            console.error('Error al obtener concesionarios:', error);
        }
    };

    const fetchDataConcesionariosByEmpresa = async (inputValue) => {
        try {
            const dataConcesionarios = await APIConcesionarios.getByEmpresaId(inputValue);
            setConcesionarios(dataConcesionarios.concesionario);
        } catch (error) {
            console.error('Error al obtener concesionarios:', error);
        }
    };

    const fetchDataMarcas = async (inputValue) => {
        try {
            const dataMarcas = await APIMarcas.get(inputValue);
            setMarcas(dataMarcas.marcas);
        } catch (error) {
            console.error('Error al obtener marcas:', error);
        }
    };

    const handlePersonasChange = (event, newValue) => {
        setSelectedValuePersonas(newValue);
        setValue('responsable_id', newValue ? newValue.id : null);
    };

    const handleConcesionariosChange = (event, newValue) => {
        const selectedId = event.target.value;
        const selectedConcesionario = concesionarios.find(concesionario => concesionario.id === parseInt(selectedId));
        setSelectedConcesionario(selectedConcesionario);
        setValue('concesionario_id', selectedId);
        const idConcesionario = JSON.parse(localStorage.getItem('user'))?.id_concesionario;
        if (idConcesionario != null) {
            fetchDataUsuarios(selectedId);
        } else {
            fetchDataUsuariosByEmpresa(JSON.parse(localStorage.getItem('user'))?.id_empresa);
        }
    };

    const handleInputMarcasChange = (event, newInputValue) => {
        fetchDataMarcas(newInputValue);
    };

    const handleMarcasChange = async (event, newValue) => {
        setSelectedValueMarcas(newValue);
        setSelectedValueModelos(null);
        setValue('marca_id', newValue ? newValue.id : null);
        const dataModelos = await APIModelos.getByMarcaId(newValue.id);
        setModelos(dataModelos.modelos);
    };

    const handleModelosChange = (event, newValue) => {
        setSelectedValueModelos(newValue);
        setValue('modelo_id', newValue ? newValue.id : null);
    };

    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description"
            maxWidth="md">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fs-4 pt-3">Nueva Solicitud de Baja</p>
            </DialogTitle>
            <DialogContent className='scroll-container'>
                <DialogContentText id="confirm-description">
                    <h5>Datos de recogida</h5>
                    <form className="form col-12 mb-4 nueva-baja-form" onSubmit={handleSubmit(submit)}>
                        <div className="w-100 d-flex flex-wrap nueva-baja-form__container">
                            <div className="form-group">
                                <label htmlFor="concesionario_id">Selecciona un concesionario</label>
                                <select
                                    className="form-control"
                                    value={selectedConcesionario ? selectedConcesionario.id : ""}
                                    onChange={handleConcesionariosChange}
                                >
                                    <option value="" disabled>
                                        Seleccionar concesionario...
                                    </option>
                                    {concesionarios.map((concesionario) => (
                                        <option key={concesionario.id} value={concesionario.id}>
                                            {concesionario.nombre}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-invalid">
                                    {errors.selectedConcesionario?.type === "required" && "Concesionario es obligatorio"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="responsable_id">Selecciona un responsable</label>
                                <Autocomplete
                                    options={personas}
                                    getOptionLabel={(option) => option.nombre + " " + option.apellido1}
                                    onChange={handlePersonasChange}
                                    value={selectedValuePersonas}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                        />
                                    )}
                                    noOptionsText="Escriba para encontrar el responsable"
                                />
                                <div className="form-invalid">
                                    {errors.selectedValuePersonas?.type === "required" && "Población es obligatoria"}
                                </div>
                            </div>
                        </div>
                        <div className='nueva-baja-form__container'>
                            <h5 style={{ width: "100%" }} className='pt-3'>Datos comprobación baja</h5>
                            <div className="form-group">
                                <label htmlFor="marca_id">Marca</label>
                                <Autocomplete
                                    options={marcas}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={(event, newValue) => handleMarcasChange(event, newValue)}
                                    onInputChange={handleInputMarcasChange}
                                    value={selectedValueMarcas}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                        />
                                    )}
                                    noOptionsText="Escriba para encontrar la marca"
                                />
                                <div className="form-invalid">
                                    {errors.selectedValueMarcas?.type === "required" && "Marca es obligatoria"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="modelo_id">Modelo</label>
                                <Autocomplete
                                    options={modelos}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleModelosChange}
                                    value={selectedValueModelos}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                        />
                                    )}
                                    noOptionsText="Escriba para encontrar el modelo"
                                />
                                <div className="form-invalid">
                                    {errors.selectedValueModelos?.type === "required" && "Modelo es obligatoria"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="dnicif">Núm DNI / CIF Titular</label>
                                <input className='form-control' id="dnicif" type="text" {...register("dnicif", { required: true })} size={120} defaultValue="" />
                                <div className="form-invalid">
                                    {errors.dnicif && "Introduce un DNI o CIF"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="matricula">Núm Matricula</label>
                                <input className='form-control' id="matricula" type="text" {...register("matricula", { required: true })} size={120} defaultValue="" />
                                <div className="form-invalid">
                                    {errors.matricula && "Introduce una matricula"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="bastidor">Núm Bastidor</label>
                                <input className='form-control' id="bastidor" type="text" {...register("bastidor", { required: true })} size={120} defaultValue="" />
                                <div className="form-invalid">
                                    {errors.bastidor && "Introduce un bastidor"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="obs">Observaciones</label>
                                <textarea className='form-control' id="obs" {...register("obs")} size={1200} defaultValue="" />
                                <div className="form-invalid">
                                    {errors.obs && "Introduce un bastidor"}
                                </div>
                            </div>
                            <div className='pb-2' style={{ width: "100%" }}>
                                <Alert severity="info">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={validar}
                                            onChange={handleChange}
                                        />&nbsp;
                                        El vehículo tiene una antigüedad inferior a 10 años
                                    </label>
                                </Alert>
                            </div>
                        </div>
                        <div className='nueva-baja-form__container'>
                            <h5 style={{ width: "100%" }} className='pt-3'>Documentación necesaria</h5>
                            <div className='pb-2' style={{ width: "100%" }}>
                                <Alert severity="info">Encontrara dos enlaces por documento; el primero cuenta con información parcialmente rellenada y el segundo con el documento en formato plantilla. Complete la información y suba el fichero. </Alert>
                            </div>
                            <div className="form-group">
                                <label htmlFor="titular">Titular del vehículo</label>
                                <select
                                    id="titular"
                                    className="form-control"
                                    onChange={handleTitularChange}
                                    required="true"
                                >
                                    <option value="">Selecciona un titular</option>
                                    {Titulares.map((titular) => (
                                        <option key={titular.id} value={titular.id}>
                                            {titular.nombre}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-invalid">
                                    {(errors.titular) && "Seleccione una opción por favor"}
                                </div>
                            </div>

                            {selectedTitular === "2" && (
                                <>
                                    <h5 style={{ width: "100%" }} className='pt-3'>Documentos de empresa</h5>
                                    <div className="form-group">
                                        <label htmlFor="doc_baja_definitiva_empresa">Doc. Baja Definitiva
                                            <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_baja_definitiva_empresa")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a>
                                            <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_baja_definitiva_empresa")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a>
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_baja_definitiva_empresa", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_baja_definitiva_empresa')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_baja_definitiva_empresa) && "El documento de Baja Definitiva es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_autorizacion_empresa">Autorización  <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_autorizacion_empresa")}>
                                            <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                        </a><a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_autorizacion_empresa")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_autorizacion_empresa", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_autorizacion_empresa')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_autorizacion_empresa) && "El documento de Autorización es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_repre_juridica">Repres. personas jurídicas  <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_repre_juridica")}>
                                            <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                        </a><a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_repre_juridica")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_repre_juridica", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_repre_juridica')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_repre_juridica) && "El documento de Representación es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_dni_administrador">Fotocopia DNI Administrador</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_dni_administrador", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_dni_administrador')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_dni_administrador) && "El documento de DNI Administrador es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_cif_empresa">Documento CIF empresa</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_cif_empresa", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_cif_empresa')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_cif_empresa) && "El documento CIF de Empresa es obligatorio!"}
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedTitular === "1" && (
                                <>
                                    <h5 style={{ width: "100%" }} className='pt-3'>Documentos del particular</h5>
                                    <div className="form-group">
                                        <label htmlFor="doc_baja_definitiva">Doc. Baja Definitiva <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_baja_definitiva")}>
                                            <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                        </a><a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_baja_definitiva")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_baja_definitiva", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_baja_definitiva')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_baja_definitiva) && "El documento de Baja Definitiva es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_autorizacion">Autorización <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_autorizacion")}>
                                            <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                        </a><a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_autorizacion")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_autorizacion", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_autorizacion')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_autorizacion) && "El documento de Autorización es obligatorio!"}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doc_dni_titular">Fotocopia DNI Titular</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_dni_titular", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_dni_titular')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_dni_titular) && "El documento de DNI es obligatorio!"}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="form-group">
                                <label htmlFor="doc_vehiculo">¿Dispone de la documentación?</label>
                                <select
                                    id="doc_vehiculo"
                                    className="form-control"
                                    onChange={handleDocVehiculoChange}
                                    required="true"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {DocVehiculo.map((docv) => (
                                        <option key={docv.id} value={docv.id}>
                                            {docv.nombre}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-invalid">
                                    {(errors.doc_vehiculo) && "Seleccione una opción por favor"}
                                </div>
                            </div>
                            {selectedDocv === "0" && (
                                <>
                                    <div style={{ width: "100%" }} className="form-group">
                                        <Alert severity="warning">El original de ficha técnica y permiso de circulación deben estar dentro del vehículo en el momento de la retirada.</Alert>
                                    </div>
                                </>
                            )}
                            {selectedDocv === "1" && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="doc_vehiculo_extravio">Declaración de extravio <a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkClick(event, "doc_vehiculo_extravio")}>
                                            <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                        </a><a style={{ marginLeft: "0.3rem" }} href="#" onClick={(event) => handleLinkBaseClick(event, "doc_vehiculo_extravio")}>
                                                <FontAwesomeIcon className='nueva-baja-form__container--icons' icon={faFilePdf} />
                                            </a></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            {...register("doc_vehiculo_extravio", { required: true })}
                                            onChange={(event) => handleFileChange(event, 'doc_vehiculo_extravio')}
                                            accept="application/pdf"
                                        />
                                        <div className="form-invalid">
                                            {(errors.doc_vehiculo_extravio) && "El documento de extravio es obligatorio!"}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-100 d-flex justify-content-end mt-2 nueva-baja-form__btns-container">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar
                            </button>
                        </div>
                    </form>
                    <ViewerDialog
                        dataUrl={dataUrl}
                        open={viewerOpen}
                        onClose={handleCloseViewer}
                    />
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}