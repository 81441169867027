import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const FacturaViewer = ({ dataUrl }) => {
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      const iframe = document.getElementById('viewer');
      iframe.src = dataUrl[currentUrlIndex];
    };

    fetchData();
  }, [dataUrl, currentUrlIndex]);

  const totalDocuments = dataUrl.length;

  const handleNextDocument = () => {
    // Cambiar al siguiente documento en el array
    setCurrentUrlIndex((prevIndex) => Math.min(prevIndex + 1, totalDocuments - 1));
  };

  const handlePrevDocument = () => {
    // Cambiar al documento anterior en el array
    setCurrentUrlIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  
  return (
    <div>
      {(
        <button disabled={!(currentUrlIndex != 0)} className="visor-pdf-btn visor-pdf-btn__left" onClick={handlePrevDocument}>
          <FontAwesomeIcon icon={faChevronLeft} /> <span>Ant.</span> 
        </button>
      )}

      {(
        <button disabled={!(currentUrlIndex < totalDocuments - 1 && (currentUrlIndex + 1 < totalDocuments))} className="visor-pdf-btn visor-pdf-btn__right" onClick={handleNextDocument}>
          <span>Sig.</span> <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}

      <iframe
        id="viewer"
        title="Visor PDF"
        width="100%"
        height="600"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default FacturaViewer;
