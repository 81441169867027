import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns'; // Install date-fns library: npm install date-fns
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import ViewerDialog from "../../HistoricoBajas/Dialogs/ViewerDialog";
import SwiperSlider from '../../shared/components/CompraVehiculos/SwiperSlider';
import { APICompras } from "../../../../../services/api_mutua/compras/ofertas";

export default function InfoVehiculoWallapop({
    onConfirmAction,
    onClose,
    state,
    vehicleInfo, // New prop to receive information
}) {
    const {
        register,
        formState: { errors },
    } = useForm({});

    const vehicleInfoFields = [
        { "name": "id", "label": "ID", "type": "number" },
        { "name": "title", "label": "Título", "type": "text" },
        { "name": "creation_date", "label": "Fecha Creación", "type": "date" },
        { "name": "brand", "label": "Marca", "type": "text" },
        { "name": "model", "label": "Model", "type": "text" },
        { "name": "year", "label": "Año", "type": "number" },
        { "name": "state", "label": "Estado", "type": "number" },
        { "name": "location", "label": "Localización", "type": "text" },
        { "name": "km", "label": "KM", "type": "text" },
        { "name": "price", "label": "Precio", "type": "number" },
        { "name": "description", "label": "Descripción", "type": "textarea" }

    ];

    const [images, setImages] = useState([]); // Initialize images state as an empty array

    useEffect(() => {
        // Convertir las cadenas de URLs separadas por comas en arrays
        const imagesSmallArray = vehicleInfo.imagesSmall ? vehicleInfo.imagesSmall.split(',') : [];
        const imagesBigArray = vehicleInfo.imagesBig ? vehicleInfo.imagesBig.split(',') : [];

        // Mapear las imágenes para usar las small en thumbnail y las big en original
        const formattedImages = imagesSmallArray.map((smallImage, index) => ({
            original: imagesBigArray[index] || smallImage, // Usa la imagen "big" si está disponible, si no usa la "small"
            thumbnail: smallImage, // Usa la imagen "small" para thumbnail
        }));

        // Actualizar el estado de las imágenes
        setImages(formattedImages || []);
    }, [vehicleInfo.id, vehicleInfo]);

    const formatDate = (dateString) => {
        if (dateString) {
            return format(new Date(dateString), 'dd-MM-yyyy'); // Customize format as needed
        }
        return '';
    };

    return (
        <Dialog
            open={state}
            onClose={onClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description"
            maxWidth="md">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fs-4 pt-3">Información ampliada del vehículo</p>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="scroll-container">
                <DialogContentText id="confirm-description">
                    <br />
                    <form>
                        <Grid container spacing={2}>
                            {/* ... form fields rendered using vehicleInfoFields */}
                            {vehicleInfoFields.map((field, index) => (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <TextField
                                        {...register(field.name, { required: true })}
                                        label={field.label}
                                        value={field.type === 'date' ? formatDate(vehicleInfo[field.name]) : vehicleInfo[field.name]} // Parse date if type is 'date'
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        fullWidth
                                        disabled
                                        type={'text'} // Set type based on field type
                                    />
                                </Grid>
                            ))}

                            <SwiperSlider images={images} />

                        </Grid>
                    </form>
                    {/* Your ViewerDialog component (if needed) */}
                    <ViewerDialog /* ... props for viewer dialog */ />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}