import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { APITramites } from "../../../../../services/api/bajas/tramites";

export default function InfoModal({
    title,
    content,
    state,
    width,
    onClose,
    tramite
}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({});

    const [open, setOpen] = useState(state);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const submit = async (data, e) => {
        e.preventDefault();
        const submitButton = e.target.querySelector('button[type="submit"]');
        submitButton.disabled = true; // Desactivar el botón de submit
        data.tramite = tramite;
        if (tramite.precio != null) {
            try {
                data.validado = true;
                await APITramites.updateValidado(tramite.id, data);
                tramite.validado = true;
                showToast('Precio validado con éxito');
                onClose();
            } catch (err) {
                showErrorToast('Error al validar el precio. Por favor, inténtalo de nuevo más tarde.');
            } finally {
                submitButton.disabled = false; // Volver a habilitar el botón de submit
            }
        } else {
            try {
                await APITramites.updatePrecio(tramite.id, data);
                tramite.precio = data.precio;
                showToast('Precio actualizado con éxito');
                onClose();
            } catch (err) {
                showErrorToast('Error al actualizar el precio. Por favor, inténtalo de nuevo más tarde.');
            } finally {
                submitButton.disabled = false; // Volver a habilitar el botón de submit
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={width ? width : 'sm'}
            aria-labelledby="info-title"
            aria-describedby="info-description">
            <DialogTitle id="info-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="text-uppercase fw-bold">{title}</p>
                    <span title="cerrar" className="close" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                {content}
                <form className="form col-12 mb-4 nueva-baja-form" onSubmit={handleSubmit(submit)}>
                    {tramite.precio === null ? (
                        <>
                            <div className="w-100 d-flex flex-wrap nueva-baja-form__container">
                                <div className="form-group">
                                    <label htmlFor="responsable_id">Introduce un precio</label>
                                    <input className='form-control' id="precio" type="number" {...register("precio", { required: true })} size={8} defaultValue="" />
                                </div>
                            </div>
                            <div className="form-invalid">
                                {errors.precio?.type === "required" && "Precio es obligatorio"}
                            </div>
                            <div className="w-100 d-flex justify-content-end mt-2 nueva-baja-form__btns-container">
                                <button type="submit" className="btn btn-primary">Enviar</button>
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar
                                </button>
                            </div>
                        </>
                    ) : <>
                        <div className="w-100 d-flex flex-wrap nueva-baja-form__container">
                            <div className="form-group">
                                <input className='form-control' id="precio" type="number" value={tramite.precio} readOnly={true} />
                            </div>
                        </div>
                        <div className="form-invalid">
                            {errors.precio?.type === "required" && "Precio es obligatorio"}
                        </div>
                        <div className="w-100 d-flex justify-content-end mt-2 nueva-baja-form__btns-container">
                            <button type="submit" className="btn btn-primary">Validar</button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar
                            </button>
                        </div>
                    </>
                    }
                </form>
            </DialogContent>
        </Dialog>
    );
}