import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, FreeMode, Zoom } from 'swiper/modules'
import imgDefault from '../../../../../../../images/placeholder.jpg'
import './swiperSlider.scss'

export default function SwiperSlider({
    images
}) {
    return (
        <div className="mySwiper">
            <div>
                {images?.length > 0
                    ? <Swiper
                            style={{
                                '--swiper-navigation-color': '#215732',
                                '--swiper-pagination-color': '#215732',
                            }}
                            spaceBetween={10}
                            navigation={true}
                            zoom={true}
                            pagination={true}
                            modules={[FreeMode, Navigation, Zoom, Pagination]}
                            initialSlide={0}
                        >
                            {images?.map((img, idx) => (
                                <SwiperSlide key={idx} >
                                    <div className='mySwiper__img swiper-zoom-container'><img src={img.original} alt={'img' + idx} /></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    : <div className='mySwiper__img'><img src={imgDefault} alt='img-default' className='img-default'/></div>}
            </div>
        </div>
    );
}