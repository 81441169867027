import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import { APIDesguaces } from "../../../../services/api/bajas/desguaces";
import { APIPoblaciones } from "../../../../services/api/bajas/poblaciones";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './Desguaces.scss';

export default function Desguaces({ company, isEdit, onSubmitCompany }) {
  const [poblaciones, setPoblaciones] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [desguaces, setDesguaces] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      nombre_fiscal: "",
      cif: "",
      direccion: "",
      codigo_postal: "",
      email: "",
      telefono: "",
    },
  });

  const fetchData = async (inputValue) => {
    try {
      const dataPoblaciones = await APIPoblaciones.get(inputValue);
      setPoblaciones(dataPoblaciones.poblaciones);
    } catch (error) {
      console.error('Error al obtener trámites:', error);
    }
  };

  const fetchDesguace = async (inputValue) => {
    try {
      const dataDesguaces = await APIDesguaces.get();
      setDesguaces(dataDesguaces.desguaces);
      // If desguace data is available, set the form values
      if (dataDesguaces.desguaces && dataDesguaces.desguaces.length > 0) {
        const desguaceData = dataDesguaces.desguaces[0]; // Assuming you're using the first desguace
        reset({
          nombre_fiscal: desguaceData.nombre_fiscal,
          cif: desguaceData.cif,
          direccion: desguaceData.direccion,
          codigo_postal: desguaceData.codigo_postal,
          email: desguaceData.email,
          telefono: desguaceData.telefono,
          id_poblacion: desguaceData.id_poblacion, // Adding id_poblacion
        });
        setSelectedValue(desguaceData.id_poblacion_bv_poblacione);
      }
    } catch (error) {
      console.error('Error al obtener datos del desguace:', error);
    }
  };

  useEffect(() => {
    if (company) {
      reset(company);
    } else {
      reset();
    }
    fetchDesguace();
  }, [company]);

  const handleInputChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    if (newInputValue.length > 2 && newInputValue !== '') {
      fetchData(newInputValue);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    setValue('id_poblacion', newValue ? newValue.id : null);
    setValue('codigo_postal', newValue ? newValue.codigo_postal : null);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log(data);
    const documentoForm = new FormData();
    try {
      const res = await APIDesguaces.create(data);
      showToast('Desguace creado con éxito');
      return;
    } catch (err) {
      showErrorToast('Error al crear el desguace. Por favor, inténtalo de nuevo más tarde.');
      return;
    }
  };

  const showToast = (message) => {
    toast.info(message, {
      toastId: "info-movs",
      position: 'top-right', // You can customize the position
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-right', // You can customize the position
    });
  };

  const isFormReadOnly = JSON.parse(localStorage.getItem('user'))?.rol_id !== 1;

  // Función para aplicar readOnly a los inputs y deshabilitar los botones
  const applyReadOnly = (element) => {
    if (element.type === 'text' || element.type === 'textarea') {
      element.readOnly = isFormReadOnly;
    } else if (element.type === 'button' || element.type === 'submit') {
      element.disabled = isFormReadOnly;
    } else if (element.classList.contains('MuiAutocomplete-input')) {
      // Para el input dentro de Autocomplete
      element.readOnly = isFormReadOnly;
    }
  };

  // Función para recorrer y aplicar a los elementos del formulario
  const applyReadOnlyToForm = (form) => {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      applyReadOnly(formElements[i]);
    }
  };

  return (
    <div className="content pt-3 desguaces">
      <ToastContainer />
      <h1>Datos Contacto Desguace</h1>
      <p className="mb-2 desguaces__subtitle">Introduzca o actualice los datos del desguace</p>
      <form className="w-100 form" ref={(form) => form && applyReadOnlyToForm(form)} onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100 d-flex flex-wrap empresa-form">
          <div className="form-group">
            <label htmlFor="nombre_fiscal">Nombre Fiscal</label>
            <input
              type="text"
              className="form-control"
              {...register("nombre_fiscal", { required: true, maxLength: 50 })}
              defaultValue={""}
            />
            <div className="form-invalid">
              {errors.nombre_fiscal?.type === "required" && "Nombre fiscal es obligatorio"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="cif">CIF</label>
            <input
              type="text"
              className="form-control"
              {...register("cif", { required: true })}
              defaultValue={isEdit ? company.cif : ""}
            />
            <div className="form-invalid">
              {errors.cif?.type === "required" && "CIF es obligatorio"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="direccion">Dirección</label>
            <input
              type="text"
              className="form-control"
              {...register("direccion", { required: true })}
              defaultValue={""}
            />
            <div className="form-invalid">
              {errors.direccion?.type === "required" && "Dirección es obligatoria"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="id_poblacion">Población</label>
            <Autocomplete
              disabled={isFormReadOnly}
              options={poblaciones}
              getOptionLabel={(option) => option.poblacion || ""}
              onChange={handleChange}
              onInputChange={handleInputChange}
              value={selectedValue}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                />
              )}
              noOptionsText="Escriba al menos 3 letras para encontrar su población"
            />
            <div className="form-invalid">
              {errors.selectedValue?.type === "required" && "Población es obligatoria"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="codigo_postal">Código Postal</label>
            <input
              type="text"
              className="form-control"
              {...register("codigo_postal")}
              defaultValue={""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="form-control"
              {...register("email", { required: true })}
              defaultValue={""}
            />
            <div className="form-invalid">
              {errors.email?.type === "required" && "Email es obligatorio"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="text"
              className="form-control"
              {...register("telefono")}
              defaultValue={""}
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="id_persona_contacto">Persona de Contacto</label>
            <input
              type="text"
              className="form-control"
              {...register("id_persona_contacto")}
            />
          </div> */}
        </div>

        <button type="submit" className="btn btn-primary align-self-end">
          Guardar
        </button>
      </form>
    </div>
  );
}