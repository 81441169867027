import { useLocation } from 'react-router-dom'
import useScreenWidth from '../../../../../../../hooks/useScreenWidth'
import './SkeletonEachResult.scss'

const SkeletonEachResult = () => {
  const screenWidth = useScreenWidth()
  const location = useLocation()

  return (
    <div className={`skeleton-each-result ${location.pathname === '/compra-mutua' ? 'mutua' : ''}`}>
      <div className='skeleton-each-result__container'>
       <span className='skeleton-each-result__container--first id skeleton-each-result-loading-animation'></span>
       <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
       <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
       <span className='skeleton-each-result__container--first text skeleton-each-result-loading-animation'></span>
       <span className={`skeleton-each-result__container--second text skeleton-each-result-loading-animation ${location.pathname === '/compra-mutua' ? 'mutua' : ''}`}></span>
       <span className='skeleton-each-result__container--second text skeleton-each-result-loading-animation'></span>
       <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
       <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
       { 
            location.pathname === '/compra-fiatc' && 
            <>
            <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--second text skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--first text skeleton-each-result-loading-animation'></span>
              </>
        }
       {
        screenWidth < 992 && <>
          <span className='skeleton-each-result__container--first text skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--first text skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--second text skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container--first text skeleton-each-result-loading-animation'></span>
          { 
            location.pathname === '/compra-fiatc' && 
              <>
                <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--second skeleton-each-result-loading-animation'></span>
                <span className='skeleton-each-result__container--first skeleton-each-result-loading-animation'></span>
              </>
          }
        </>
       }
       <p className='skeleton-each-result__container-btns'>
          <span className='skeleton-each-result__container-btns--btn skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container-btns--btn skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container-btns--btn skeleton-each-result-loading-animation'></span>
          <span className='skeleton-each-result__container-btns--btn skeleton-each-result-loading-animation'></span>
       </p>
      </div>
    </div>
  )
}

export default SkeletonEachResult
