const ColumnHeader = ({ title, onFilter, onSort, sortOrder }) => {
  return (
    <th>
        <div>
            <span>{title}</span>
        </div>
    </th>
  );
}

export default ColumnHeader
