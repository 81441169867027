import API from '../../index';

export const APIConcesionarios = {
    get: async () => {
        return await API.get('/concesionarios').then(res => res.data);
    },
    getById: async (concesionarioId) => {
        return await API.get(`/concesionarios/${concesionarioId}`).then(res => res.data);
    },
    getByEmpresaId: async (empresaId) => {
        return await API.get(`/concesionarios/empresa/${empresaId}`).then(res => res.data);
    },
    create: async (body) => {
        return await API.post('/concesionarios', body).then(res => res.data);
    },
    update: async (concesionarioId, body) => {
        return await API.put(`/concesionarios/${concesionarioId}`, body).then(res => res.data);
    },
    delete: async (concesionarioId) => {
        return await API.delete(`/concesionarios/${concesionarioId}`).then(res => res.data);
    },
};
