import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import FacturaViewer from '../../Viewer';

const ViewerDialog = ({ dataUrl, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Visor PDF</DialogTitle>
      <DialogContent>
        <FacturaViewer dataUrl={dataUrl} />
      </DialogContent>
    </Dialog>
  );
};

export default ViewerDialog;