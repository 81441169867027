import API_MUTUA from '../../index';

export const APICompras = {
    obtenerDatos: async (body) => {
        return await API_MUTUA.post('/integration/obtenerDatos', body).then(res => res.data);
    },
    rechazarOferta: async (body) => {
        return await API_MUTUA.post('/integration/rechazarOferta', body).then(res => res.data);
    },
    aceptarOferta: async (body) => {
        return await API_MUTUA.post('/integration/aceptarOferta', body).then(res => res.data);
    },
    recogerImagenes: async (id) => {
        return await API_MUTUA.get(`/integration/recogerImagenes?IDOffer=${id}`).then(res => res.data);
    },
    recogerPdf: async (id_siniestro, id_encargo) => {
        return await API_MUTUA.get(`/integration/recogerPDF?id_siniestro=${id_siniestro}&id_encargo=${id_encargo}`).then(res => res.data);
    },
};