import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';

const TooltipIcon = ({title, icon}) => {
  return( 
    <Tooltip title={title} 
        arrow
        placement="left-start" sx={{ fontSize: "30" }}
        slotProps={{
            tooltip: {
                sx: {
                    color: "#215732",
                    backgroundColor: "#ffff",
                },
            },
        }}>
        <FontAwesomeIcon icon={icon} />
    </Tooltip>)
}

export default TooltipIcon
