import API from '../../index';

export const APIMarcas = {
    get: async () => {
        return await API.get('/marcas').then(res => res.data);
    },
    getById: async (marcaId) => {
        return await API.get(`/marcas/${marcaId}`).then(res => res.data);
    },
};
