import API from '../../index';

export const APIModelos = {
    get: async () => {
        return await API.get('/modelos').then(res => res.data);
    },
    getById: async (modeloId) => {
        return await API.get(`/modelos/${modeloId}`).then(res => res.data);
    },
    getByMarcaId: async (marcaId) => {
        return await API.get(`/modelos/marca/${marcaId}`).then(res => res.data);
    },
};
