import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIDocumentosAprobados } from "../../../../../services/api/bajas/documentos_aprobados";

export default function AprobarBajaDialog({
    tramite,
    onConfirmAction,
    onClose,
    state,
}) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({});

    const handleConfirm = async (confirm) => {
        try {
            onClose();
            onConfirmAction(tramite.id, confirm);
        } catch (error) {
            showErrorToast('Error al aprobar la baja. Por favor, inténtalo de nuevo más tarde.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleFileChange = (event, id) => {
        setValue(id + "_file", null);
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
            const archivoSeleccionado = fileInput.files[0];
            const reader = new FileReader();
            // Obtener el tipo MIME y el nombre del archivo
            const mimeType = archivoSeleccionado.type;
            const fileName = archivoSeleccionado.name;
            reader.onload = () => {
                const base64String = reader.result;
                setValue(id + "_file", { base64String: base64String, mimetype: mimeType, originalname: fileName });
            };
            reader.readAsDataURL(archivoSeleccionado);
        } else {
            setValue(id + "_file", null);
        }
    };

    const submit = async (data, e) => {
        e.preventDefault();
        data.tramite = tramite;
        try {
            const res = await APIDocumentosAprobados.create(data);
            showToast('Aprobación creada con éxito');
            handleConfirm(res);
            return;
        } catch (err) {
            showErrorToast('Error al crear la aprobación. Por favor, inténtalo de nuevo más tarde.');
            return;
        }
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description"
            maxWidth="sm">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fs-4 pt-3">Aprobar Baja</p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    <form className="form col-12 mb-4" onSubmit={handleSubmit(submit)}>
                        <div className="w-100">
                            <div className="form-group col-md-12 mb-3">
                                <label htmlFor="certificado">Certificado de destrucción</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    {...register("certificado", { required: true })}
                                    onChange={(event) => handleFileChange(event, 'certificado')}
                                />
                                <div className="form-invalid">
                                    {(errors.certificado) && "El certificado de destrucción es obligatorio!"}
                                </div>
                            </div>
                            <div className="form-group col-md-12 mb-3">
                                <label htmlFor="bajadgt">Baja DGT</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    {...register("bajadgt", { required: true })}
                                    onChange={(event) => handleFileChange(event, 'bajadgt')}
                                />
                                <div className="form-invalid">
                                    {(errors.bajadgt) && "El documento baja DGT es obligatorio!"}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end mt-2 aprobar-baja-dialog__btns">
                            <button type="submit" className="btn btn-primary">Aprobar</button>
                            <button style={{marginLeft: "0.5rem"}} type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar
                            </button>
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}