import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { APIUsuarios } from "../../../../services/api/bajas/usuarios";
import { APIEmpresas } from "../../../../services/api/bajas/empresas";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { APIConcesionarios } from "../../../../services/api/bajas/concesionarios";
import { APIRoles } from "../../../../services/api/bajas/roles";

export default function Usuarios({ usuario, isEdit, onSubmitUsuario }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      nombre: "",
      apellido1: "",
      apellido2: "",
      email: "",
      telefono: "",
      password: "",
      id_empresa: null,
      id_concesionario: null,
    },
  });

  const [empresas, setEmpresas] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedValueEmpresas, setSelectedValueEmpresas] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [concesionarios, setConcesionarios] = useState([]);
  const [selectedValueConcesionarios, setSelectedValueConcesionarios] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedValueRoles, setSelectedValueRoles] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedUsuario, setSelectedUsuario] = useState(null);

  const isFormReadOnly = JSON.parse(localStorage.getItem('user'))?.rol_id === 1;

  const fetchDataAllUsuarios = async () => {
    try {
      const idConcesionario = isFormReadOnly ? -1 : JSON.parse(localStorage.getItem('user'))?.id_concesionario;
      const idEmpresa = isFormReadOnly ? -1 : JSON.parse(localStorage.getItem('user'))?.id_empresa;
      const dataUsuarios = await APIUsuarios.get(idConcesionario, idEmpresa);
      setUsuarios(dataUsuarios.usuarios);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    }
  };

  const fetchDataRoles = async () => {
    try {
      const dataRoles = await APIRoles.get();
      setRoles(dataRoles.roles);
    } catch (error) {
      console.error('Error al obtener roles:', error);
    }
  };

  const fetchDataEmpresas = async (inputValue) => {
    try {
      const dataEmpresas = await APIEmpresas.get(inputValue);
      setEmpresas(dataEmpresas.empresas);
    } catch (error) {
      console.error('Error al obtener empresas:', error);
    }
  };

  const fetchDataConcesionarios = async (idEmpresa) => {
    try {
      const dataConcesionario = await APIConcesionarios.get(idEmpresa);
      setConcesionarios(dataConcesionario.concesionarios);
    } catch (error) {
      console.error('Error al obtener los concesionarios:', error);
    }
  };

  const handleInputEmpresasChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    fetchDataEmpresas(newInputValue);
  };

  const handleEmpresasChange = (event, newValue) => {
    setSelectedValueEmpresas(newValue);
    setValue('id_empresa', newValue ? newValue.id : null);
    fetchDataConcesionarios(newValue.id);
  };

  const handleConcesionariosChange = (event, newValue) => {
    setSelectedValueConcesionarios(newValue);
    setValue('id_concesionario', newValue ? newValue.id : null);
  };

  const handleChangeRol = (event, newValue) => {
    setSelectedValueRoles(newValue);
    setValue('rol_id', newValue ? newValue.id : null);
  };

  useEffect(() => {
    setSelectedValueConcesionarios(null);
    setSelectedValueEmpresas(null);
    if (selectedUsuario) {
      reset(selectedUsuario);
    } else {
      reset();
    }
    fetchDataAllUsuarios();
    fetchDataRoles();
  }, [selectedUsuario]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (selectedUsuario) {
        // Si hay un usuario seleccionado, actualiza
        const res = await APIUsuarios.update(selectedUsuario.id, data);
        showToast('Usuario actualizado con éxito');
      } else {
        // Si no hay usuario seleccionado, crea uno nuevo
        const res = await APIUsuarios.create(data);
        showToast('Usuario creado con éxito');
      }
      // Después de crear o actualizar, puedes limpiar el formulario
      reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        email: "",
        telefono: "",
        password: "",
        id_empresa: null,
        id_concesionario: null,
      });
      setSelectedUsuario(null);
      fetchDataAllUsuarios();
    } catch (err) {
      showErrorToast('Error al procesar la operación. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  const showToast = (message) => {
    toast.info(message, {
      toastId: "info-movs",
      position: 'top-right', // You can customize the position
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-right', // You can customize the position
    });
  };

  const handleEliminarClick = async (id) => {
    try {
      await APIUsuarios.delete(id);
      showToast('Usuario eliminado con éxito');
      fetchDataAllUsuarios();
      reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        email: "",
        telefono: "",
        password: "",
        id_empresa: null,
        id_concesionario: null,
      });
      setSelectedUsuario(null);
      return;
    } catch (err) {
      showErrorToast('Error al eliminar el usuario. Por favor, inténtalo de nuevo más tarde.');
      return;
    }
  };

  const handleTableRowClick = (usuario) => {
    setSelectedUsuario(usuario);
    setSelectedValueConcesionarios(usuario.bv_concesionarios);
    setValue('id_concesionario', usuario ? usuario.id_concesionario : null);
    setSelectedValueEmpresas(usuario.id_empresa_bv_empresa);
    setValue('id_empresa', usuario ? usuario.id_empresa : null);
  };

  return (
    <div className="content pt-3 facturas">
      <ToastContainer />
      <h1>Usuarios</h1>
      <div className='facturas__table-container table-responsive'>
        <table className="table table-bordered table-striped">
          <thead className="bg-secondary text-white">
            <tr>
              <th>Nombre</th>
              <th>Apellido 1</th>
              <th>Apellido 2</th>
              <th>Email</th>
              <th>Teléfono</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr
                key={usuario.id}
                onClick={() => handleTableRowClick(usuario)}
                className="cursor-pointer"
              >
                <td>{usuario.nombre}</td>
                <td>{usuario.apellido1}</td>
                <td>{usuario.apellido2}</td>
                <td>{usuario.email}</td>
                <td>{usuario.telefono}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='facturas__container-form'>
        <div className='facturas__container-form--form-box'>
          <p className="mb-2 facturas__title-inputs">Introduzca o actualice los datos de un usuario</p>
          <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100 d-flex flex-wrap empresa-form">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("nombre", { required: true, maxLength: 50 })}
                  defaultValue={""}
                />
                <div className="form-invalid">
                  {errors.nombre?.type === "required" && "Nombre es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="apellido1">Apellido 1</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("apellido1", { required: true, maxLength: 50 })}
                  defaultValue={""}
                />
                <div className="form-invalid">
                  {errors.apellido1?.type === "required" && "Apellido 1 es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="apellido2">Apellido 2</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("apellido2", { required: false, maxLength: 50 })}
                  defaultValue={""}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("email", { required: true })}
                  defaultValue={""}
                  autoComplete="new-email" // Intenta deshabilitar el autocompletado
                />
                <div className="form-invalid">
                  {errors.email?.type === "required" && "Email es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("telefono")}
                  defaultValue={""}
                />
                {/* Add error handling for telefono if needed */}
              </div>
              <div className="form-group">
                {(roles && roles.length > 0) && (
                  <>
                    <label htmlFor="rol_id">Rol</label>
                    <select
                      className='form-control'
                      {...register("rol_id")}
                      disabled={!isFormReadOnly}  // Deshabilitar el select si es isFormReadOnly
                      value={isFormReadOnly ? undefined : roles[1]?.id}  // Establecer el valor seleccionado si es isFormReadOnly
                    >
                      <option value="">
                        Seleccionar rol...
                      </option>
                      {roles.map((op) => (
                        <option key={'rol_id' + op.id} value={op.id}>
                          {op.descripcion}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  {...register("password")}
                  defaultValue={""}
                  autoComplete="new-password" // Intenta deshabilitar el autocompletado
                />
                {/* Add error handling for telefono if needed */}
              </div>
              <div className="form-group">
                <label htmlFor="id_empresa">Empresa</label>
                <Autocomplete
                  options={empresas}
                  getOptionLabel={(option) => option.nombre_fiscal}
                  onChange={handleEmpresasChange}
                  onInputChange={handleInputEmpresasChange}
                  value={selectedValueEmpresas}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba para encontrar su empresa"
                />
                <div className="form-invalid">
                  {errors.selectedValueEmpresas?.type === "required" && "Empresa es obligatoria"}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="id_concesionario">Concesionario</label>
                <Autocomplete
                  options={concesionarios}
                  getOptionLabel={(option) => option.nombre}
                  onChange={handleConcesionariosChange}
                  value={selectedValueConcesionarios}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                    />
                  )}
                  noOptionsText="Escriba para encontrar su concesionario"
                />
              </div>
              <div className="w-100 d-flex flex-wrap facturas__btn-container">
                {selectedUsuario && (
                  <button type="button"
                    className="btn btn-secondary align-self-end ml-2"
                    onClick={() => {
                      reset({
                        nombre: "",
                        apellido1: "",
                        apellido2: "",
                        email: "",
                        telefono: "",
                        password: "",
                        id_empresa: null,
                        id_concesionario: null,
                      });
                      setSelectedUsuario(null);
                    }}>
                    Reset
                  </button>
                )}
                <button type="submit" className="btn btn-primary align-self-end">
                  {selectedUsuario ? "Actualizar" : "Crear"}
                </button>
                {selectedUsuario && (
                  <button
                    type="button"
                    className="btn btn-secondary align-self-end ml-2"
                    onClick={() => handleEliminarClick(selectedUsuario.id)}
                  >
                    Eliminar
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}