import React, { useEffect, useState } from "react";
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIDocumentacion } from "../../../../services/api/bajas/documentacion";
import { APIEmpresas } from "../../../../services/api/bajas/empresas";
import { APIConcesionarios } from "../../../../services/api/bajas/concesionarios";
import { APIFacturas } from "../../../../services/api/bajas/facturas";
import ViewerDialog from "../HistoricoBajas/Dialogs/ViewerDialog.js";
import './Facturas.scss'

export default function Facturas({ company, isEdit, onSubmitCompany }) {
  const [empresas, setEmpresas] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [selectedValueEmpresas, setSelectedValueEmpresas] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedValueConcesionarios, setSelectedValueConcesionarios] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [selectedFacturas, setSelectedFacturas] = useState(null); // Nuevo estado para manejar la opción seleccionada
  const [viewerOpen, setViewerOpen] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      month: "",
      year: "",
      doc_facturas: "",
      doc_comprobantes: "",
      id_empresa: null,
      id_concesionario: null
    },
  });

  const fetchDataEmpresas = async (inputValue) => {
    try {
      const dataEmpresas = await APIEmpresas.get(inputValue);
      setEmpresas(dataEmpresas.empresas);
    } catch (error) {
      console.error('Error al obtener empresas:', error);
    }
  };

  const fetchDataConcesionarios = async (idEmpresa) => {
    try {
      const dataConcesionario = await APIConcesionarios.get(idEmpresa);
      setConcesionarios(dataConcesionario.concesionarios);
    } catch (error) {
      console.error('Error al obtener los concesionarios:', error);
    }
  };

  const isFormReadOnly = JSON.parse(localStorage.getItem('user'))?.rol_id !== 1;

  // Función para aplicar readOnly a los inputs y deshabilitar los botones
  const applyReadOnly = (element) => {
    if (element.type === 'text' || element.type === 'textarea') {
      element.readOnly = isFormReadOnly;
    } else if (element.type === 'button' || element.type === 'submit') {
      element.disabled = isFormReadOnly;
    } else if (element.classList.contains('MuiAutocomplete-input')) {
      // Para el input dentro de Autocomplete
      element.readOnly = isFormReadOnly;
    }
  };

  // Función para recorrer y aplicar a los elementos del formulario
  const applyReadOnlyToForm = (form) => {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      applyReadOnly(formElements[i]);
    }
  };

  const fetchDataFacturas = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      const dataFacturas = await APIFacturas.get(currentUser.id_empresa);
      setFacturas(dataFacturas.facturas);
    } catch (error) {
      console.error('Error al obtener las facturas:', error);
    }
  };

  useEffect(() => {
    if (selectedFacturas) {
      reset(selectedFacturas);
    } else {
      reset();
    }
    fetchDataFacturas();
  }, [selectedFacturas]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const documentoForm = new FormData();

    // Agregar campos de texto al FormData
    Object.keys(data).forEach((key) => {
      if (key !== "doc_facturas" && key !== "doc_comprobantes") {
        documentoForm.append(key, data[key]);
      } else {
        if (key === "doc_facturas") {
          for (let i = 0; i < data.doc_facturas.length; i++) {
            documentoForm.append(`doc_facturas`, data.doc_facturas[i]);
          }
        }
        if (key === "doc_comprobantes") {
          for (let i = 0; i < data.doc_comprobantes.length; i++) {
            documentoForm.append(`doc_comprobantes`, data.doc_comprobantes[i]);
          }
        }
      }
    });

    try {
      if (selectedFacturas) {
        // Si hay una factura seleccionada, actualiza
        const res = await APIFacturas.update(selectedFacturas.id, documentoForm);
        showToast('Factura actualizada con éxito');
      } else {
        // Si no hay factura seleccionada, crea una nueva
        const res = await APIFacturas.create(documentoForm);
        showToast('Factura creada con éxito');
      }

      // Después de crear o actualizar, puedes limpiar el formulario
      reset({
        month: "",
        year: "",
        doc_facturas: "",
        doc_comprobantes: "",
        id_empresa: null,
        id_concesionario: null
      });
      setSelectedValueEmpresas(null);
      setSelectedValueConcesionarios(null);
      setValue("facturas", null);
      setSelectedFacturas(null);
      fetchDataFacturas();
    } catch (err) {
      showErrorToast('Error al procesar la operación. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  const handleInputEmpresasChange = (event, newInputValue) => {
    // Realiza la llamada a fetchData solo si newInputValue tiene al menos tres caracteres y no es la cadena vacía
    fetchDataEmpresas(newInputValue);
  };

  const handleEmpresasChange = (event, newValue) => {
    setSelectedValueEmpresas(newValue);
    setValue('id_empresa', newValue ? newValue.id : null);
    fetchDataConcesionarios(newValue.id);
  };

  const handleConcesionariosChange = (event, newValue) => {
    setSelectedValueConcesionarios(newValue);
    setValue('id_concesionario', newValue ? newValue.id : null);
  };

  const showToast = (message) => {
    toast.info(message, {
      toastId: "info-movs",
      position: 'top-right', // You can customize the position
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-right', // You can customize the position
    });
  };

  const handleCloseViewer = () => {
    setViewerOpen(false);
  };

  const handleLinkBaseClick = async (event, urlDoc) => {
    // Evitar que el navegador siga el enlace por defecto
    event.preventDefault();
    // Obtener todos los valores del formulario
    try {
      // Llamar a la función post de tu API pasando el objeto formData y el archivo PDF
      const response = await APIDocumentacion.getDoc({ urlDoc: urlDoc });
      const { pdfBase64 } = response; // Asegúrate de ajustar la propiedad según la respuesta de tu backend
      // Crear una URL de datos con el contenido base64
      const pdfDataUrl = `data:application/pdf;base64,${pdfBase64}`;
      setDataUrl([pdfDataUrl]);
      setViewerOpen(true);
    } catch (error) {
      // Manejar errores, por ejemplo, mostrar un mensaje de error
      showErrorToast('Error al generar el PDF:', error);
    }
  };

  const handleTableRowClick = (factura) => {
    setSelectedFacturas(factura);
    setValue('id_factura', factura ? factura.id : null);
    setSelectedValueEmpresas(factura.id_empresa_bv_empresa);
    setValue('id_empresa', factura ? factura.id_empresa : null);
    setSelectedValueConcesionarios(factura.id_concesionario_bv_concesionario);
    setValue('id_concesionario', factura ? factura.id_concesionario : null);
  };

  return (
    <div className="content pt-3 facturas">
      <ToastContainer />
      <h1>Facturas</h1>
      <div className='facturas__table-container table-responsive'>
        <table className="table table-bordered table-striped" style={{ cursor: 'pointer' }}>
          <thead className="bg-secondary text-white">
            <tr>
              <th>Id</th>
              <th>Mes</th>
              <th>Año</th>
              <th>Empresa</th>
              <th>Concesionario</th>
              <th>Facturas</th>
              <th>Comprobantes</th>
              <th>Conciliada</th>
            </tr>
          </thead>
          <tbody>
            {facturas.map((facturas) => (
              <tr
                key={facturas.id}
                onClick={() => handleTableRowClick(facturas)}
                className="cursor-pointer"
              >
                <td>{facturas.id}</td>
                <td>{facturas.month}</td>
                <td>{facturas.year}</td>
                <td>{facturas.id_empresa_bv_empresa?.nombre_fiscal}</td>
                <td>{facturas.id_concesionario_bv_concesionario?.nombre}</td>
                <td>{facturas.doc_facturas ? facturas.doc_facturas.split(',').length : 'No hay facturas cargadas'}</td>
                <td>{facturas.doc_comprobantes ? facturas.doc_comprobantes.split(',').length : 'No hay comprobantes cargados'}</td>
                <td className='td-icon'>
                  {facturas.conciliada == null || facturas.conciliada === false || facturas.conciliada === undefined ? (
                    <FontAwesomeIcon icon={faWarning} className='td-icon__warning' />
                  ) : (
                    <FontAwesomeIcon icon={faCheckCircle} className='td-icon__check' />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='facturas__container-form'>
        <div className='facturas__container-form--form-box'>
          <p className="mb-2 facturas__title-inputs">Introduzca o actualice los datos de la factura</p>
          <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100 d-flex flex-wrap empresa-form">
              {selectedFacturas && (
                <div className="form-group">
                  <label htmlFor="id">Id Factura</label>
                  <input
                    type="label"
                    className="form-control"
                    disabled
                    value={selectedFacturas.id}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="month">Mes</label>
                <select
                  className="form-control"
                  disabled={selectedFacturas?.id}
                  {...register("month", {
                    required: "El Mes es obligatorio",
                  })}
                >
                  <option value="">Seleccionar Mes</option>
                  <option value="1">Enero</option>
                  <option value="2">Febrero</option>
                  <option value="3">Marzo</option>
                  <option value="4">Abril</option>
                  <option value="5">Mayo</option>
                  <option value="6">Junio</option>
                  <option value="7">Julio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
                <div className="form-invalid">
                  {errors.month?.message}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="year">Año</label>
                <input
                  placeholder="yyyy"
                  type="text"
                  className="form-control"
                  disabled={selectedFacturas?.id}
                  {...register("year", { required: true })}
                  maxLength={4} // Limita la entrada a 4 caracteres
                />
                <div className="form-invalid">
                  {errors.year?.type === "required" && "El año es obligatoria"}
                  {errors.year?.type === "maxLength" && "Introduzca 4 numeros"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="id_empresa">Empresa</label>
                <Autocomplete
                  options={empresas}
                  getOptionLabel={(option) => option.nombre_fiscal}
                  onChange={handleEmpresasChange}
                  onInputChange={handleInputEmpresasChange}
                  value={selectedValueEmpresas}
                  disabled={selectedFacturas?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba para encontrar su empresa"
                />
                <div className="form-invalid">
                  {errors.selectedValueEmpresas?.type === "required" && "La empresa es obligatoria"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="id_concesionario">Concesionario</label>
                <Autocomplete
                  options={concesionarios}
                  getOptionLabel={(option) => option.nombre}
                  onChange={handleConcesionariosChange}
                  value={selectedValueConcesionarios}
                  disabled={selectedFacturas?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                    />
                  )}
                  noOptionsText="Escriba para encontrar su concesionario"
                />
                <div className="form-invalid">
                  {errors.selectedValueConcesionarios?.type === "required" && "El concesionario es obligatorio"}
                </div>
              </div>
              <div className="form-group">
              </div>

              <div className="form-group">
                <label htmlFor="doc_facturas">Facturas</label>
                {
                  JSON.parse(localStorage.getItem('user'))?.rol_id === 2 && !selectedFacturas && !selectedFacturas?.doc_facturas ? (
                    <input
                      type="file"
                      className="form-control"
                      {...register("doc_facturas", { required: 'Se requiere al menos una factura.' })}
                      accept=".pdf,.jpg,.jpeg,.png"
                      multiple
                    />
                  ) : (
                    selectedFacturas ? (
                      <div>
                        {selectedFacturas.doc_facturas ? (
                          <div>
                            <p>{selectedFacturas.doc_facturas.split(',').length} Facturas cargadas</p>
                            {/* Generar un enlace para cada factura */}
                            {selectedFacturas.doc_facturas.split(',').map((factura, index) => (
                              <div key={index}>
                                <a href="#" onClick={(event) => handleLinkBaseClick(event, factura)}>
                                  {`Factura ${index + 1}`}
                                </a>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>Las facturas serán cargadas por el concesionario</p>
                        )}
                      </div>
                    ) : <p>Las facturas serán cargadas por el concesionario</p>
                  )
                }
              </div>
              <div className="form-group">
                <label htmlFor="doc_comprobantes">Comprobantes</label>
                {
                  JSON.parse(localStorage.getItem('user'))?.rol_id === 3 && selectedFacturas && !selectedFacturas?.doc_comprobantes ? (
                    <input
                      type="file"
                      className="form-control"
                      {...register("doc_comprobantes", { required: 'Se requiere al menos un comprobante.' })}
                      accept=".pdf,.jpg,.jpeg,.png"
                      multiple
                    />
                  ) : (
                    selectedFacturas ? (
                      <div>
                        {selectedFacturas.doc_comprobantes ? (
                          <div>
                            <p>{selectedFacturas.doc_comprobantes.split(',').length} Comprobantes cargados</p>
                            {/* Generar un enlace para cada comprobante */}
                            {selectedFacturas.doc_comprobantes.split(',').map((comprobante, index) => (
                              <div key={index}>
                                <a href="#" onClick={(event) => handleLinkBaseClick(event, comprobante)}>
                                  {`Comprobante ${index + 1}`}
                                </a>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>Los comprobantes serán cargados por el desguace</p>
                        )}
                      </div>
                    ) : <p>Los comprobantes serán cargados por el desguace</p>
                  )
                }
              </div>
            </div>
            <div className="w-100 d-flex flex-wrap facturas__btn-container">
              {selectedFacturas && (
                <button type="button"
                  className="btn btn-secondary align-self-end ml-2"
                  onClick={() => {
                    reset({
                      month: "",
                      year: "",
                      doc_facturas: "",
                      doc_comprobantes: "",
                      id_empresa: null,
                      id_concesionario: null
                    });
                    setSelectedValueEmpresas(null);
                    setSelectedValueConcesionarios(null);
                    setValue("facturas", null);
                    setSelectedFacturas(null);
                  }}>
                  Reset
                </button>
              )}
              <button type="submit" className="btn btn-primary">
                {selectedFacturas ? "Actualizar" : "Crear"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ViewerDialog
        dataUrl={dataUrl}
        open={viewerOpen}
        onClose={handleCloseViewer}
      />
    </div>
  );
}