import API from '../../index';

export const APIDesguaces = {
    get: async () => {
        return await API.get('/desguaces').then(res => res.data);
    },
    getById: async (desguaceId) => {
        return await API.get(`/desguaces/${desguaceId}`).then(res => res.data);
    },
    create: async (body) => {
        return await API.post('/desguaces', body).then(res => res.data);
    },
    update: async (desguaceId, body) => {
        return await API.put(`/desguaces/${desguaceId}`, body).then(res => res.data);
    },
    delete: async (desguaceId) => {
        return await API.delete(`/desguaces/${desguaceId}`).then(res => res.data);
    },
};
