import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { changePassword } from "../../../../services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MiCuenta.scss'

export default function Micuenta({ setToken }) {
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem("user")) || {};

    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        // Retrieve num_empleado and password from userData
        const { num_empleado, password } = userData;

        const response = await changePassword({
            num_empleado,
            password: newPassword,
        });

        if (response && response.success) {
            // Assuming setToken is a function to update the token in your state
            localStorage.setItem("user", JSON.stringify(response.user));
            showToast('Password cambiado correctamente');
        }
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    return (
        <div className="content pt-3 mi-cuenta">
            <ToastContainer />
            <h1>Mi cuenta</h1>
            <p className="mb-2 mi-cuenta__subtitle">Aquí aparecen los datos de tu cuenta y puedes cambiar las credenciales</p>
            
            <fieldset className="mi-cuenta__mis-datos">
                <legend>Mis datos</legend>
                <div>
                    <p>
                        Nombre:
                        <span>{userData.nombre}</span>
                    </p>
                    <p>
                        Número de empleado:
                        <span>{userData.num_empleado}</span>
                    </p>
                    <p>
                        Email:
                        <span>{userData.email}</span>
                    </p>
                </div>
            </fieldset>

            <fieldset className="mi-cuenta__credenciales">
                <legend>Cambiar credenciales</legend>
                <div className='mi-cuenta__credenciales--form-container'>
                    <div className='mi-cuenta__credenciales--form-container--form'>
                        <div>
                        <label>Nuevo password:</label>
                        <input
                            type="password"
                            className='form-control'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div>
                        <label>Repetir password:</label>
                        <input
                            type="password"
                            className='form-control'
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        </div>
                    </div>
                    <button className="btn btn-primary" onClick={handlePasswordChange}>
                        Cambiar Contraseña
                    </button>
                </div>
            </fieldset>
        </div>
    );
}

Micuenta.propTypes = {
    setToken: PropTypes.func.isRequired,
};
