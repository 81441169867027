import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns'; // Install date-fns library: npm install date-fns
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import ViewerDialog from "../../HistoricoBajas/Dialogs/ViewerDialog";
import SwiperSlider from '../../shared/components/CompraVehiculos/SwiperSlider';
import { APICompras } from "../../../../../services/api_mutua/compras/ofertas/";

export default function InfoVehiculoMutua({
    onConfirmAction,
    onClose,
    state,
    vehicleInfo, // New prop to receive information
}) {
    const {
        register,
        formState: { errors },
    } = useForm({});

    const vehicleInfoFields = [
        { "name": "id", "label": "ID", "type": "number" },
        { "name": "sender", "label": "Sender", "type": "number" },
        { "name": "id_siniestro", "label": "ID Siniestro", "type": "text" },
        { "name": "id_encargo", "label": "ID Encargo", "type": "text" },
        { "name": "vehiculo", "label": "Vehículo", "type": "text" },
        { "name": "fecha_recepcion", "label": "Fecha Recepción", "type": "date" },
        { "name": "fecha_actualizacion", "label": "Fecha Actualización", "type": "date" },
        { "name": "estado", "label": "Estado", "type": "number" },
        { "name": "fecha_resolucion", "label": "Fecha Resolución", "type": "date" },
        { "name": "resolucion", "label": "Resolución", "type": "text" },
        { "name": "ubicacion", "label": "Ubicación", "type": "text" },
        { "name": "provincia", "label": "Provincia", "type": "text" },
        { "name": "km", "label": "KM", "type": "text" },
        { "name": "precio", "label": "Precio", "type": "number" },
        { "name": "subject", "label": "Subject", "type": "text" }
    ];

    const [images, setImages] = useState([]); // Initialize images state as an empty array

    useEffect(() => {
        // Fetch image data on component mount or vehicleInfo.id change
        const fetchData = async () => {
            try {
                const ofertasData = await APICompras.recogerImagenes(vehicleInfo.id);
                const formattedImages = ofertasData.Imagenes.map((image) => ({
                    original: `data:image/png;base64,${image}`,
                    thumbnail: `data:image/png;base64,${image}`,
                }));
                setImages(formattedImages || []); // Set images state with formatted data or empty array
            } catch (error) {
                console.error("Error fetching image data:", error);
                // Handle error appropriately (e.g., display an error message)
            }
        };
        fetchData();
    }, [vehicleInfo.id, vehicleInfo]);

    const formatDate = (dateString) => {
        if (dateString) {
            return format(new Date(dateString), 'dd-MM-yyyy'); // Customize format as needed
        }
        return '';
    };

    return (
        <Dialog
            open={state}
            onClose={onClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description"
            maxWidth="md">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fs-4 pt-3">Información ampliada del vehículo</p>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="scroll-container">
                <DialogContentText id="confirm-description">
                    <br />
                    <form>
                        <Grid container spacing={2}>
                            {/* ... form fields rendered using vehicleInfoFields */}
                            {vehicleInfoFields.map((field, index) => (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <TextField
                                        {...register(field.name, { required: true })}
                                        label={field.label}
                                        value={field.type === 'date' ? formatDate(vehicleInfo[field.name]) : vehicleInfo[field.name]} // Parse date if type is 'date'
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        fullWidth
                                        disabled
                                        type={'text'} // Set type based on field type
                                    />
                                </Grid>
                            ))}

                            <SwiperSlider images={images} />

                        </Grid>
                    </form>
                    {/* Your ViewerDialog component (if needed) */}
                    <ViewerDialog /* ... props for viewer dialog */ />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}