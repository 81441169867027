import API_MUTUA from '../../index';

export const APICompras = {
    obtenerDatos: async (body) => {
        return await API_MUTUA.post('/wallapop/obtenerDatos', body).then(res => res.data);
    },
    rechazarOferta: async (body) => {
        return await API_MUTUA.put('/wallapop/rechazarOferta', body).then(res => res.data);
    },
    aceptarOferta: async (body) => {
        return await API_MUTUA.put('/wallapop/aceptarOferta', body).then(res => res.data);
    }
};